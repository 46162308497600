import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { UserService } from '../_services';
import { AuthenticationService } from '../_services';

import { ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

@Component({
  selector: 'app-profileicon',
  templateUrl: './profileicon.component.html',
  styleUrls: ['./profileicon.component.css']
})
export class ProfileiconComponent implements OnInit {
	public currentUser: User;
	public loading: boolean;
	public imageSrc: string;
	public file: File;
	public showSaveAvatar: boolean = false;

	constructor(private userService: UserService,
			private router: Router,
			private serviceAuthentication: AuthenticationService) {
		
	}


	ngOnInit() {
		console.log(localStorage.getItem('currentUser'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        
        if (this.currentUser.avatar != null) {
        	this.imageSrc = this.currentUser.avatar;
        }
        
		this.file = null;
		this.showSaveAvatar = false;
	}
	
	uploadFile(event) {
		$("#message").html("");
		let files = event.target.files;
		if (files.length > 0) {
	        const file = files[0];

	        const reader = new FileReader();
	        reader.onload = e => this.imageSrc = reader.result;

	        reader.readAsDataURL(file);

	        if (file.size <= 50000000) {
	        	this.showSaveAvatar = true;
	        	this.file = file;
	        } else {
	        	$("#message").html("The file size is more than 50Mb");
	        }
		}
	}
	
	saveAvatar() {
		this.userService.uploadUserAvatar(this.imageSrc).subscribe((res: any []) => {
			this.currentUser.avatar = this.imageSrc;
			var data = JSON.stringify(this.currentUser);
			localStorage.setItem('currentUser', data);
			this.router.navigate(['/']);
	    },
	    error => {
	        console.log('error on changing avatar: ', error);
	    });
	}

}
