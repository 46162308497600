import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
//Google Maps Import Angular 6+
/// <reference types="googlemaps" />
import { MapsAPILoader } from '@agm/core';
import { TodoNottodoService } from '../../_services';
import { AddcityformComponent } from '../../addcityform/addcityform.component';
import { Tip } from '../../_models';
import { Router } from '@angular/router';

declare var google: any;

@Component({
  selector: 'app-addthingsnottodo',
  templateUrl: './addthingsnottodo.component.html',
  styleUrls: ['./addthingsnottodo.component.css']
})
export class AddthingsnottodoComponent implements OnInit {
	public tips: Tip[];
	public tip: string;
  
  constructor(private mapsAPILoader: MapsAPILoader, 
	private router: Router,
    private ngZone: NgZone,
    private addcityform: AddcityformComponent,
    private todoNottodoService: TodoNottodoService) { 
	  this.tips = this.addcityform.addcityModel.nottodo;
	  
	  if (this.tips == null) {
		  this.tips = [];
	  }
  }

    //Reference to Autocomplete Search
    @ViewChild("search")
    public searchElementRef: ElementRef;

    public latitude: number;
    public longitude: number;
    public zoom: number;

    ngOnInit() {
    }
  
	addTips() {
		// TODO understand if we need to save the tips HERE
		this.addcityform.addcityModel.nottodo = this.tips;
		this.router.navigate(['/addcity']);
	}
	
	addTip() {
		this.tips.push(new Tip(this.tip, 0));
		this.tip = "";
	}
	
	deleteTip(tip : Tip) {
		this.tips.splice(this.tips.indexOf(tip), 1);
	}
}
