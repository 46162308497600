import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { Place } from '../_models';
import { Comment } from '../_models';
import { Tip } from '../_models';
import { Photo } from '../_models';
import { Addcity } from '../_models';
import { UserService, PlacesService } from '../_services';

import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
//Google Maps Import Angular 6+
/// <reference types="googlemaps" />
import { MapsAPILoader } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

@Component({
  selector: 'app-citydetail',
  templateUrl: './citydetail.component.html',
  styleUrls: ['./citydetail.component.css']
})
export class CitydetailComponent implements OnInit {
	city: Addcity;
  	users: User[] = [];
	month: string;
	year: number;
	counter: number;
	zoom: number;
	page: number;
	liked: boolean;
	public currentUser: User;
	public markerIdToDelete: number;
	public error: string;
	public info: string;

  constructor(private userService: UserService, private mapsAPILoader: MapsAPILoader, 
      private ngZone: NgZone, private route: ActivatedRoute, private router: Router,
      private servicePlace: PlacesService) {
	  
	  this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }
  
  clipboard() {
	    document.addEventListener('copy', (e: ClipboardEvent) => {
	      e.clipboardData.setData('text/plain', ('https://www.vanderon.app/citydetail/' + this.city.id));
	      e.preventDefault();
	      document.removeEventListener('copy', null);
	    });
	    document.execCommand('copy');
	  }

  hideTips (idList : string ) {
	if ($("#" + idList).is(":hidden")) {
		$("#" + idList).show();
	} else {
		$("#" + idList).hide();
	}
  }
  
  expandPictures () {
	$(".hidden-pictures").show();
	$(".preview-pictures").hide();
  }
  
  ngOnInit() {
	  this.error = "";
	  this.info = "";
	  // are you logged?
//	  if (this.currentUser == null) {
//		  this.router.navigate(['/login'], { queryParams: {error: 'You should be logged in to watch this marker'}});
//	  }
	  
	  this.route.params.subscribe(
		params => {
			let id = +params['marker_id'];
			this.loadCity(id);
		});
  }
  
  openDialogGift () {
	  if(this.currentUser != null) {
		  $("#marker-gift-loading").show();
		  $("#marker-gift-not-loading").hide();
		  $("#dialog-gift").show();
	  }
  }
 
  
  gift () {
	  this.error = "";
	  this.info = "";
	  
	  $("#dialog-gift").hide();
	  this.servicePlace.gift(this.city.id).subscribe(
			    result => {
			    	console.log(result);
					if (result == "NO_ENOUGH_VAN") {
						this.error = "You don't have enough VAN for this GIFT. Check under the Wallet -> Mission section to see how to earn them";
					} if (result == "OK") {
						this.city.gifts += 1;
						this.info = "Thanks for your gift! We sent a notification to the owner of this marker!";
					} else {
						this.error = "An error occured during the process";
					}
					
					$("#marker-gift-loading").hide();
					$("#marker-gift-not-loading").show();
			     },
			    error => {
			    	console.error(error);
			    	this.error = "An error occured during the process";
			     } )/*.add(() => { console.log("C ");})*/;
  }
  
  like (like: boolean) {
	  if(this.currentUser != null) {
		  $("#marker-like-loading").show();
		  $("#marker-like-not-loading").hide();
		  this.servicePlace.like(this.city.id).subscribe()
			.add(() => {
				if (like) {
					this.liked = true;
					this.city.likes++;
				} else {
					this.liked = false;
					this.city.likes--;
				}
				$("#marker-like-loading").hide();
				$("#marker-like-not-loading").show();
			});
	  }
  }

  loadCity(id: number) {
	  
	  
	  this.servicePlace.getPlace(id)
	    .subscribe(res => {
	        	console.log(res);

	        	$(".custom-title").html(res.name_short);
	  			this.city = new Addcity(res.name_short);
	  			
	  			this.city.user = new User(res.user.id, res.user.name, res.user.name, null , null); 
	  			this.city.user.avatar = res.user.avatar;
	  			
	  	    	this.city.id = res.id;
	  	    	this.city.cityNameShort = res.name_short;
	  	    	
	  	    	this.city.description = res.description;
	  	    	this.city.rating = res.rating;

	  	    	this.city.location = {lat: Number(res.location.lat), long: Number(res.location.long)};
	  	    	this.city.todo = [];
	  	    	this.city.nottodo = [];
	  			
	  	    	for (let todo of res.todos) {
	  	    		if (todo.todo == 1) {
	  	    			this.city.todo.push(new Tip(todo.text ,todo.like));
	  	    		} else {
	  	    			this.city.nottodo.push(new Tip(todo.text ,todo.like));
	  	    		}
	  	    	}
	  	    	
	  	    	this.city.isvisited = false;
	  	    	
	  	    	this.city.planned = res.planned;

	  	    	// controls
	  	        if (res.chosendate != null) {
  	        		var month = new Array();
  	        		month[0] = "January";
  	        		month[1] = "February";
  	        		month[2] = "March";
  	        		month[3] = "April";
  	        		month[4] = "May";
  	        		month[5] = "June";
  	        		month[6] = "July";
  	        		month[7] = "August";
  	        		month[8] = "September";
  	        		month[9] = "October";
  	        		month[10] = "November";
  	        		month[11] = "December";
  	        		
  	        		res.chosendate = res.chosendate.replace(/\-/g, '/'); // FIX IOS
  	        		
  	        		this.month = month[new Date(res.chosendate).getMonth()];
  	        		this.year = new Date(res.chosendate).getFullYear();
  	        		this.city.chosenDate = res.chosendate;
  	        		this.city.dateVisited = res.chosendate;
  	        		
  	        		this.city.howlong = res.howlong;
  	        		
  	        		if (this.city.planned != null) {
		  	  	      	if (this.city.planned == 0) {
		  	  	      		this.city.isvisited = true;
		  	  	      	} else {
							this.city.isvisited = false;
		  	  	      	}
  	        		}
	  	        }
	  	        
	  	        this.city.likes = res.likes.length;
	  	        this.city.gifts = res.gifts.length; 
	  	        
	  	        for (let like of res.likes) {
	  	        	if(this.currentUser != null && like.user_id == this.currentUser.id) {
	  	        		this.liked = true;
	  	        	}
		  	    	  
	  	        }
	  	      
		  	    this.servicePlace.getMarkerImages(id)
		  	    	.subscribe(images => {
	  	    		this.city.photos = [];
	  	    		
//	  	    		console.log("***************** TEST **************************");
//	  	    		console.log(images);
	  	    		
	  	    		for (let image of images) {
	  	    			if (image.is_main_image == 1) {
	  	    				if (image.url_medium != null){
	  	    					this.city.photos.push(new Photo(image.url_medium,image.is_main_image)); 
	  	    				} else {
	  	    					this.city.photos.push(new Photo(image.url_image,image.is_main_image));
	  	    				}
	  	    			}
		  	    	}
	  	    		
		  	    	for (let image of images) {
		  	    		if (image.is_main_image != 1) {
		  	    			if (image.url_medium != null){
	  	    					this.city.photos.push(new Photo(image.url_medium,image.is_main_image)); 
	  	    				} else {
	  	    					this.city.photos.push(new Photo(image.url_image,image.is_main_image));
	  	    				}
		  	    		}
		  	    	}
	  	    		
	  	    		if (this.city.photos.length > 3) {
  	    				this.counter = this.city.photos.length - 2;
  	    			} else {
		  		    	this.counter = 0;
    				}
		  	    });
	  	        
	  	        
	  			/*
			  
			  this.city.isvisited = true;
		     
		      this.city.howlong = 3;
		      this.city.chosenTagList = [new Place(1, "Culture"), new Place(1, "Relax"), new Place(1, "Shopping"), new Place(1, "Nightlife")];
			 
			  this.city.location = {lat: 44.414165, long: 8.942184};
			  this.city.comments = [];
			  this.city.comments.push(new Comment(1,"Lorenzo","Giordano","/assets/avatar.png","Amazing place, I really want to go there as well!"));
			  this.city.comments.push(new Comment(2,"Luca","Milano","/assets/avatar.png","I use to travel a lot, but this city is one of the top!"));
			  this.city.comments.push(new Comment(3,"Lorenzo","Franco","/assets/avatar.png","Amazing place, I really want to go there as well!"));
			  this.city.comments.push(new Comment(4,"Luca","Fritz","/assets/avatar.png","Thanks to share your adventures!"));
			  this.city.comments.push(new Comment(5,"Fausto","Rossi","/assets/avatar.png","Glad to see your hints"));
			  this.city.comments.push(new Comment(6,"Giacomo","Torino","/assets/avatar.png","Awesome 😊😊"));
			  this.city.comments.push(new Comment(7,"Michele","Verdi","/assets/avatar.png","I had a totally different experience"));
		      
		      
		      */
	  	        
		      this.zoom = 12;
		      this.page = 0;  
	  
	  

	        });
  }
  
  public openDeleteDialog(markerId: number) {
  	$("#dialog-remove").show();
  	this.markerIdToDelete = markerId;
  }
  
  	public editMarker(markerId: number) {
	  	location.href="/addcity?id=" + markerId;
	}
  
	public openPictures(index: number) {
		$("#dialog-gallery").show();
		$("#img-gallery").attr("number", index);
		$("#img-gallery").attr("src", this.city.photos[index].url);
	}
	
	public closePictures() {
		$("#dialog-gallery").hide();
	}
	
	public changePicture() {
		var number = Number($("#img-gallery").attr("number"));
		
		number = Number(number) + 1;
		if (Number(number) >= this.city.photos.length) {
			number = 0;
		}
		
		$("#img-gallery").attr("number", number);
		$("#img-gallery").attr("src", this.city.photos[number].url);
	}
	
	public change(change: boolean) {
		var number = Number($("#img-gallery").attr("number"));
		
		if (change) {
			number = Number(number) + 1;
		} else {
			number = Number(number) - 1;
		}
		if (Number(number) >= this.city.photos.length) {
			number = 0;
		}
		
		if (Number(number) < 0) {
			number = this.city.photos.length - 1;
		}
		
		$("#img-gallery").attr("number", number);
		$("#img-gallery").attr("src", this.city.photos[number].url);
	}
  
  public closeDeleteDialog() {
  	$("#dialog-remove").hide();
  }
  
  public closeGiftDialog() {
  		$("#dialog-gift").hide();
  		$("#marker-gift-loading").hide();
  		$("#marker-gift-not-loading").show();
  }
  
  public confirmDeleteMarker() {
  	this.servicePlace.removeMarker(this.markerIdToDelete).subscribe()
  	        .add(() => {
  	        	this.closeDeleteDialog();
  	        	this.router.navigate(['/']);
  			});
  }
}
