import { Component, OnInit } from '@angular/core';
import { Place } from '../../_models';
import { AddcityformComponent } from '../../addcityform/addcityform.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-giverating',
  templateUrl: './giverating.component.html',
  styleUrls: ['./giverating.component.css']
})
export class GiveratingComponent implements OnInit {

  private place: Place;
  public rating: number;

  constructor(
    public addcityform: AddcityformComponent,
    private router: Router
  ) { 
	  this.rating = this.addcityform.addcityModel.rating;
  }

	onSelectionChange(entry): void {
		$(".starLabel").css("color","#ccc");
		for (var i = entry; i > 0; i--) { 
			$("#star"+i+"Label").css("color","#ff4959");
		}
		
	    this.rating = entry;
	}

  addRating(){
    if (this.rating == undefined){
      alert("Please Give a Rating");
    }
    else{
      console.log(this.rating);
      this.addcityform.addcityModel.rating = this.rating;
      this.router.navigate(['/addcity']);
    }
  }

  ngOnInit() {
	  for (var i = this.rating; i > 0; i--) { 
		  $("#star"+i+"Label").css("color","#ff4959");
	  }
  }

}
