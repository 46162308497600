import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-underconstruction',
  templateUrl: './underconstruction.component.html',
  styleUrls: ['./underconstruction.component.css']
})
export class UnderConstructionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
