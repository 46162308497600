import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { UserService } from '../_services';
import { NftService, PlacesService, AuthenticationService } from '../_services';
import { ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-nft',
  templateUrl: './nft.component.html',
  styleUrls: ['./nft.component.css']
})
export class NftComponent implements OnInit {
	public user_badge_id: number;
	public userBadge;
	public bids;
	public tokens;
	public viewNewBid;
	public error;
	public currentUser: User;
	
	constructor(private userService: UserService,
			private nftService: NftService,
			private servicePlace: PlacesService,
			private serviceAuthentication: AuthenticationService,
		    private route: ActivatedRoute) {
	}

  	ngOnInit() {
  		this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  		this.viewNewBid = true;
  		this.route.params.subscribe(
  				params => {
					let user_badge_id = +params['user_badge_id'];
					this.user_badge_id = user_badge_id;
					console.log("user_badge_id --> " + user_badge_id);
					
					this.nftService.getUserNft(user_badge_id).subscribe(res=>{
						this.userBadge = res;
						console.log(res);
						
						if (this.userBadge.badge.bids != null) {
							for(let bid of this.userBadge.badge.bids) {
								console.log(bid);
								if (bid.current_state == 0) {
									this.viewNewBid = false;
								}
							}
						}
						
  	    	  		});
		});
  	}
  	
  	public reload() {
  		this.viewNewBid = true;
  		this.nftService.getUserNft(this.user_badge_id).subscribe(res=>{
			this.userBadge = res;
			console.log(this.userBadge);
			
			if (this.userBadge.badge.bids != null) {
				for(let bid of this.userBadge.badge.bids) {
					console.log(bid);
					if (bid.current_state == 0) {
						this.viewNewBid = false;
					}
				}
			}
			
  		});
  	}
  	
  	public openDialogPlaceBid () {
		this.error = null;
		$("#dialog-placebid").show();
	}
	
	public closePlaceBidDialog() {
  		$("#dialog-placebid").hide();
	}
	
	cancelBid(bidId) {
		this.nftService.deleteBid(bidId).subscribe(
		    result => {
		    	console.log(result);
				this.reload();
		     },
		    error => {
		    	console.error(error);
		    } );
	}
	
	bid() {
		this.error = "";
		
		if (this.tokens <= 0 || this.tokens == null || this.tokens == "") {
			this.error = "Number of tokens not valid";
			return;
		}
		this.nftService.addBid(this.tokens, this.userBadge.badge_id).subscribe(
		    result => {
		    	console.log(result);
				this.reload();
				$("#dialog-placebid").hide();
		     },
		    error => {
		    	console.error(error);
		    	this.error = "An error occured during the process";
		    } );
	}

}
