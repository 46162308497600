import { Injectable, ViewChild, ElementRef, Input, Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY, throwError, of } from 'rxjs';
import { Http } from '@angular/http';

import { catchError, map, retry } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
//import { User } from '../_models';
import 'rxjs/Rx';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PicturesService {

  //Variables
  private authKey: string;

  //Server URL Variable
  private serverGlobal: string = environment.apiUrl;
  @Input() multiple: boolean = false;
  @ViewChild('fileInput') inputEl: ElementRef;

  constructor(private httpClient: HttpClient,
    private serviceAuthentication: AuthenticationService) {
      //Set authKey
      this.authKey =  this.serviceAuthentication.getToken();
}

  upload(data) {
      let _authKey = this.authKey;
      let headers = new HttpHeaders();
      //headers = headers.append('Content-Type', 'multipart/form-data');
      headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
      headers = headers.append('Authorization', `Bearer ${_authKey}`);
          this.httpClient
              .post(`${this.serverGlobal}:80/api/v1/upload`, data, {
                headers: headers,
                reportProgress: true,
                observe: "events"
              })
                .subscribe((data:any) => {
                  if(data.type === HttpEventType.UploadProgress){
                    console.log("Upload Progress: " + Math.round(data.loaded / data.total * 100) + "%");
                  }
                  else if (data.type === HttpEventType.Response){
                    console.log(data);
                    
                  }
                });
  }
}
