import { User } from ".";
import { Place } from ".";

export class Notification {
	id: number;
    user: User;
    when: string;
	place: Place;
    type: string; // "destination" / "photo" / "comment" / "buddy"
	referral: number;

	constructor(id, user, when, place, type, referral) {
	    this.id = id;
	    this.user = user;
	    this.when = when;
	    this.place = place;
	    this.type = type;
	    this.referral = referral;
	}
}