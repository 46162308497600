import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SearchService, AuthenticationService  } from '../_services';
import { importType } from '@angular/compiler/src/output/output_ast';
import { ReactiveFormsModule, FormControl, FormsModule } from '@angular/forms';
import { User } from '../_models';
import { Observable } from 'rxjs';

import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/do';

@Component({
  selector: 'app-searchperson',
  templateUrl: './searchperson.component.html',
  styleUrls: ['./searchperson.component.css']
})
export class SearchPersonComponent implements OnInit {

  //Variables
  public searchResults: Observable<User[]>;
  private isLoading: boolean;
  public searchField: FormControl;
  
  constructor(
    private serviceSearch: SearchService,
    private serviceAuthentication: AuthenticationService) { }

  ngOnInit() {
   
    this.searchField = new FormControl();
    this.searchResults = this.searchField.valueChanges
    .debounceTime(400)
    .distinctUntilChanged()
    .do( () => this.isLoading = true)
    .switchMap(term => this.onSearchChange(term))
    .do( () => this.isLoading = false);
  }
  
   onSearchChange(searchValue : string ): Observable<User[]> {  
    if(searchValue != null && searchValue !=''){
    var temp = this.serviceSearch.searchBuddiesResultObservable(searchValue);    
    return temp;
  }
  return Observable.of([]);
  }
}
