/*export class Place {
    id:number;
    name:string;
    

    constructor(id,name) {
        this.id = id;
        this.name = name;
      }
    }
*/
export class Place {
  id:number;
  name: string
  location: {
    lat: number
    long: number
  }
  google_city_id: string
  name_short: string
  country_long: string
  country_short: string
  elevation: number
  continent: string
  rating: number
  visited: boolean
  planning: boolean
  date: string
  howLong: number
  planned: number
  tags: string[]
  pictures: string[]

  constructor(id, name) {
    this.id = id;
    this.name = name;
  } 
}