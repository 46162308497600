import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
//Google Maps Import Angular 6+
/// <reference types="googlemaps" />
import { MapsAPILoader } from '@agm/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { PlacesService, AuthenticationService, UserService, BuddiesService } from '../_services';
import { Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { Notification, User, Place } from '../_models';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
	public notificationList: Notification[];
	public error;

  	constructor(private userService: UserService, private buddyService: BuddiesService, private router: Router,
  	      private servicePlace: PlacesService) { }
  	ngOnInit() {
  		this.notificationList = null;
  		$("#notification-loading").show();
  		this.userService.notifications()
		.subscribe(res => {
			this.notificationList = [];
			$("#notification-loading").hide();
			console.log("notifications ********************");
			console.log(res);
			console.log("(/notifications ********************");
			
			var buddies = res['buddies'];
			for (let entry of buddies) {
				if (entry.TYPE == "buddy") {
					var user = new User(entry.USER_ID,entry.NAME,"name","pw","surname");
					user.avatar = entry.AVATAR;
					
					if (user.avatar == null || user.avatar == "") {
						user.avatar = "/assets/avatar.png";
					}
					
					var createdAt = entry.CREATED_AT;
					
					this.notificationList.push(new Notification(entry.ID, user, createdAt, null, "buddy", null));
				}
			}
			
			var notifications = res['notifications'];
			
			for (let entry of notifications) {
				if (entry.type == "LIKE") {
					var userFromEntry = entry.notification_from_user[0];
					var user = new User(userFromEntry.id,userFromEntry.name,"name","pw","surname");
					user.avatar = userFromEntry.avatar;
					
					if (user.avatar == null || user.avatar == "") {
						user.avatar = "/assets/avatar.png";
					}
					
					var createdAt = entry.created_at;
					
					this.notificationList.push(new Notification(entry.id, user, createdAt, null, "like", entry.referral_id));
				}
				
				if (entry.type == "UPLOAD_AVATAR") {
					var createdAt = entry.created_at;
					this.notificationList.push(new Notification(entry.id, null, createdAt, null, "upload_avatar", null));
				}
				
				if (entry.type == "DAILY_MARKER") {
					var createdAt = entry.created_at;
					this.notificationList.push(new Notification(entry.id, null, createdAt, null, "daily_marker", null));
				}
				
				if (entry.type == "MISSION_UPDATE") {
					var createdAt = entry.created_at;
					this.notificationList.push(new Notification(entry.id, null, createdAt, null, "mission_update", null));
				}
				
				if (entry.type == "MISSION_PAID") {
					var createdAt = entry.created_at;
					this.notificationList.push(new Notification(entry.id, null, createdAt, null, "mission_paid", null));
				}
				if (entry.type == "BID_PAID") {
					var createdAt = entry.created_at;
					this.notificationList.push(new Notification(entry.id, null, createdAt, null, "bid_paid", null));
				}
				
				if (entry.type == "PAID_BID") {
					var createdAt = entry.created_at;
					var userFromEntry = entry.notification_from_user[0];
					var user = new User(userFromEntry.id,userFromEntry.name,"name","pw","surname");
					user.avatar = userFromEntry.avatar;
					
					if (user.avatar == null || user.avatar == "") {
						user.avatar = "/assets/avatar.png";
					}
					
					this.notificationList.push(new Notification(entry.id, user, createdAt, null, "paid_bid", null));
				}
				
				if (entry.type == "GENERAL") {
					var createdAt = entry.created_at;
					this.notificationList.push(new Notification(entry.id, null, createdAt, null, "general", entry.referral_id));
				}
				
				if (entry.type == "GIFT") {
					var userFromEntry = entry.notification_from_user[0];
					var user = new User(userFromEntry.id,userFromEntry.name,"name","pw","surname");
					user.avatar = userFromEntry.avatar;
					
					if (user.avatar == null || user.avatar == "") {
						user.avatar = "/assets/avatar.png";
					}
					
					var createdAt = entry.created_at;
					
					this.notificationList.push(new Notification(entry.id, user, createdAt, null, "gift", entry.referral_id));
				}
			}
			
			console.log("-------------------------------------------");
			console.log(this.notificationList);
			
		});
  		
  		
  		// to load with a web service
  		/*
  		var user1 = new User(1, "username", "Jamie", "", "Red");
  		user1.avatar = "/assets/avatar.png";
  		var user2 = new User(2, "username", "Jeremy", "", "Miller");
  		user2.avatar = "/assets/avatar.png";
  		var user3 = new User(3, "username", "Alex", "", "Sheperd");
  		user3.avatar = "/assets/avatar.png";
  		
  		this.notificationList.push(new Notification(1, user1, "2 hours ago", new Place(1, "Singapore"), "destination"));
		this.notificationList.push(new Notification(2, user2, "3 hours ago", new Place(2, "Fiji"), "photo"));
		this.notificationList.push(new Notification(3, user3, "10 hours ago", new Place(3, "Rome"), "comment"));
        */
  	}
  	
  	addBuddy(id: number, notificationId: number) {
  		console.log("add buddy with relationship ["+ id + "] " + notificationId);
  		
  		this.userService.acceptBuddy(id).subscribe();
  		this.hideNotificationRow(notificationId, true);
  	}
  	
  	visitMarker(id: number) {
  		this.router.navigate(['/citydetail/' + id], { queryParams: {from: 'notifications'}});
  	}
  	
  	markRead(id: number, notificationId: number) {
  		this.servicePlace.markNotification(id).subscribe();
  		$("#notification-" + notificationId + " .action").html("<div class='col-12' style='text-align:left'>Marked as read</div>");
  	}
  	
  	rejectBuddy(id: number, notificationId: number) {
  		console.log("reject buddy with relationship ["+ id + "] " + notificationId);
  		this.userService.declineBuddy(id).subscribe();
  		this.hideNotificationRow(notificationId, false);
  	}
  	
  	private hideNotificationRow(notificationId: number, accepted: boolean) {
//  		$("#notification-" + notificationId).hide();
  		if(accepted) {
  			$("#notification-" + notificationId + " .action").html("<div class='col-12' style='text-align:left'>You accepted the buddy</div>");
  		} else {
  			$("#notification-" + notificationId + " .action").html("<div class='col-12' style='text-align:left'>You declined the buddy</div>");
  		}
  	}
  	
  	markAllRead() {
  		this.servicePlace.markNotification(null).subscribe();
  		$(".nofriendnotification").html("<div class='col-12' style='text-align:left'>Marked as read</div>");
  		$("#dialog-alert").hide();
  	}
  	
  	public openDialogAlert() {
		this.error = null;
		$("#dialog-alert").show();
	}
  	
  	public closeAlertDialog() {
  		$("#dialog-alert").hide();
	}
}
