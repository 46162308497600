import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TodoNottodoService {


  //Variables
  private authKey: string;
  
  constructor(private httpClient: HttpClient,
    private serviceAuthentication: AuthenticationService) {
      //Set authKey
      this.authKey =  this.serviceAuthentication.getToken();
  }

//Server URL Variable
private serverGlobal: string = environment.apiUrl;



//getToDo's
getToDo(): Observable<any /*Place[]*/>{
  let _authKey = this.authKey;
  //Setting Header
  let headers = new HttpHeaders();
  headers = headers.append("Authorization", `Bearer ` + _authKey);
  headers = headers.append('Content-Type', 'application/json');
  headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
  //Get Request
  return this.httpClient.get<any/*Place[]*/>(`${this.serverGlobal}:80/api/v1/visits/3/todo`, {headers: headers});
} //getToDo's function

//getNotToDo's
getNotToDo(): Observable<any /*Place[]*/>{
  let _authKey = this.authKey;
  //Setting Header
  let headers = new HttpHeaders();
  headers = headers.append("Authorization", `Bearer ` + _authKey);
  headers = headers.append('Content-Type', 'application/json');
  headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
  //Get Request
  return this.httpClient.get<any/*Place[]*/>(`${this.serverGlobal}:80/api/v1/visits/3/notTodo`, {headers: headers});
} //getNotToDo's function

//Handling Errors
getErrorHandler(error: HttpErrorResponse){

}

//Post ToDo
postToDo(newToDo){
  const data = newToDo;
  //authorization token
  let _authKey = this.authKey;
  //setting Header
  let headers = new HttpHeaders();
  headers = headers.append('Content-Type', 'application/json');
  headers = headers.append('Access-Control-Allow-Origin', 'http://localhost:4200/');
  headers = headers.append('Authorization', `Bearer ${_authKey}`);
  //Post
  this.httpClient.post(`${this.serverGlobal}:80/api/v1/visits/3/todo`, data, {headers: headers})
                  .subscribe((data:any) => {
                    console.log(data);
                  });
}//Post ToDo function

//Post NotToDo
postNotToDo(newNotToDo){
  const data = newNotToDo;
  //authorization token
  let _authKey = this.authKey;
  //setting Header
  let headers = new HttpHeaders();
  headers = headers.append('Content-Type', 'application/json');
  headers = headers.append('Access-Control-Allow-Origin', 'http://localhost:4200/');
  headers = headers.append('Authorization', `Bearer ${_authKey}`);
  //Post
  this.httpClient.post(`${this.serverGlobal}:80/api/v1/visits/3/notTodo`, data, {headers: headers})
                  .subscribe((data:any) => {
                    console.log(data);
                  });
}//Post NotToDo function

}
