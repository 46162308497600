import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
//Google Maps Import Angular 6+
/// <reference types="googlemaps" />
import { MapsAPILoader,AgmMap } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window'
import { PlacesService, AuthenticationService  } from '../_services';
import { countries, languages, continents} from 'countries-list';
import { User } from '../_models';
import { Comment } from '../_models';
import { Tip } from '../_models';
import { Photo } from '../_models';
import { Place, Addcity, Marker } from '../_models';
import { AddcityformComponent } from '../addcityform/addcityform.component';
import { AddcityService } from '../_services/addcity.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';

declare var google: any;

@Component({
  selector: 'app-addcity',
  templateUrl: './addcity.component.html',
  styleUrls: ['./addcity.component.css']
})

export class AddcityComponent implements OnInit {
	@ViewChild(AgmMap)
	public agmMap: AgmMap;
	
	public markerIconPath:any = {
	        url: 'assets/icon_pin.png',
	        scaledSize: {
	          width: 25,
	          height: 35
	        }};
	public markerIconPathPlanned:any = {
        url: 'assets/icon_pin_planned.png',
        scaledSize: {
          width: 25,
          height: 35
        }};
	public searchResults: Addcity[];
	public selectedResult: Addcity;
	public searchResultsLoading: boolean = false;
	public loading: boolean = false;
	
	public hideNewMarker: boolean;
	public alreadyCreatedMarkerId = null;
	
	//Variables
	private authKey: string;
	private type: string;
	
	//Server URL Variable
	private serverGlobal: string = environment.apiUrl;

  private newPlace: any;
  private osmPlaces: any;
  public rating: number;
  private cityOption: string;

  public chosendate: string;
  public howlong: number;
  public planned: number;
  public isvisited: boolean;
  
	public showSearchResults: boolean = false;
	public showAddCity: boolean = true;
	public showSearchBar: boolean = true; 
	public showCityName: boolean = false;

  private place1 = new Place(1, "Genf");

  constructor(private httpClient: HttpClient,
		  private router: Router,
		  private mapsAPILoader: MapsAPILoader, 
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private servicePlace: PlacesService,
    private addcityform: AddcityformComponent,
    private addcityService: AddcityService,
    private serviceAuthentication: AuthenticationService) {
	  this.authKey =  this.serviceAuthentication.getToken();
	  this.chosendate ="";
	  this.howlong=0;
	  this.planned=null;
	  if (this.addcityform.addcityModel != null && this.addcityform.addcityModel.cityname != null && this.addcityform.addcityModel.cityname != "") {
		  console.log(this.addcityform.addcityModel.cityname);
		  this.showSearchBar = false;
		  this.showCityName = true;
	  }
	}


  //Reference to Autocomplete Search
  @ViewChild("search")
  public searchElementRef: ElementRef;

	onSelectionChange(entry): void {
	  this.cityOption = entry;
	  console.log(this.cityOption);
	}

  	cancelSearch() {
		$("#search").val("");
		$(".cancel-column").hide();
		this.showAddCity = true;
		this.showSearchResults = false;
	}
  
  	selectCity(city : Addcity ) {  
  		// set user in the city
  		city.user = JSON.parse(localStorage.getItem('currentUser'));
  		this.selectedResult = city;
  		console.log("** city **");
  		console.log(city);
  		console.log("** / city **");
  		
  		if (this.addcityform.addcityModel.id == null) {
  			console.log("this is a new marker");
	  		this.servicePlace.getMarkersWithTheSameLocation(city.location.lat, city.location.long) 
		    .subscribe(res => {
		    	this.hideNewMarker = false;
		    	// check if there are already marker with the same location and get the first
		    	if (res.length > 0) {
		    		this.hideNewMarker = true;
		    		this.alreadyCreatedMarkerId = res[0].id;
		    	}
		    });
  		} else {
  			console.log("you're editing a marker");
  		}
  		
  		
  		$(".custom-title").html(city.cityNameShort);
  		
  		this.addcityform.addcityModel.cityname = city.cityname;
  		this.addcityform.addcityModel.cityNameShort = city.cityNameShort;
  		this.addcityform.addcityModel.location = city.location;
  		this.addcityform.addcityModel.osmId = city.osmId;
  		this.addcityform.addcityModel.country = city.country;
  		this.addcityform.addcityModel.user = city.user;
  		this.addcityform.addcityModel.country_code = city.country_code;
		
  		this.cancelSearch();
  		this.showSearchBar = false;
  		this.showAddCity = true;
  		this.showCityName = true;
  	}
  	
  	changeCity() {  
  		this.showSearchBar = true;
  		this.showCityName = false;
  	}
  	
  	handle(e : KeyboardEvent) {
  		var searchValue = new String($("#search").val());
  		
  		if (searchValue.length >= 2) {
  			$("#search-places-button").removeClass("button-added");
  			$("#search-places-button").addClass("button-add");
  		} else {
  			$("#search-places-button").removeClass("button-add");
  			$("#search-places-button").addClass("button-added");
  		}
  		
        if(e.keyCode === 13){
        	this.onSearchChange();
        }
    }
  	
	onSearchChange() {  
		this.searchResultsLoading = true;
		var searchValue = new String($("#search").val()) + '';
		
		if (searchValue != null && searchValue.length >= 3) {
			if (searchValue != null && searchValue != '') {
				$(".cancel-column").show();
				this.showSearchResults = true;
				this.showAddCity = false;
			} else {
				this.showAddCity = true;
				this.showSearchResults = false;
			}
			
			this.getLocationsV2(searchValue);
		}
	}

	getLocations(query: string) { 
		let _authKey = this.authKey;
		this.searchResults = [];
		//Setting Header
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
        headers = headers.append("Authorization", `Bearer ` + _authKey);

		this.httpClient.post<any>(`${this.serverGlobal}/api/suggestions`, {query: query} ,{headers: headers})
			.subscribe(res => {
			for (let entry of res){
				var city = new Addcity(entry.display_name);
				
				var decimals = 8;
				
				const truncateByDecimalPlace = (value, numDecimalPlaces) =>
				  Math.trunc(value * Math.pow(10, numDecimalPlaces)) / Math.pow(10, numDecimalPlaces);
				
				var lat = truncateByDecimalPlace(entry.lat, decimals);
				var long = truncateByDecimalPlace(entry.lon, decimals);
					
				// set values
				var firstPartName = city.cityname.split(",");
				city.cityNameShort = firstPartName[0];
				city.location = { lat:  lat, long: long};
				city.osmId = entry.osm_id;
				city.country = entry.address.country;
				city.country_code = entry.address.country_code;
				this.searchResults.push(city);
			}
			this.searchResultsLoading = false;
	    },

	    error => {
	        console.log('Log the error here: ', error);
	    });
	}
	
	getLocationsV2(query: string) { 
		let _authKey = this.authKey;
		this.searchResults = [];
		//Setting Header
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
        headers = headers.append("Authorization", `Bearer ` + _authKey);

		this.httpClient.post<any>(`${this.serverGlobal}/api/suggestions_v2`, {query: query} ,{headers: headers})
			.subscribe(res => {
				
			for (let entry of res.results){
				var city = new Addcity(entry.formatted_address);
				
				var decimals = 8;
				
				const truncateByDecimalPlace = (value, numDecimalPlaces) =>
				  Math.trunc(value * Math.pow(10, numDecimalPlaces)) / Math.pow(10, numDecimalPlaces);
				
				var lat = truncateByDecimalPlace(entry.geometry.location.lat, decimals);
				var long = truncateByDecimalPlace(entry.geometry.location.lng, decimals);
					
				// set values
//				var firstPartName = city.cityname.split(",");
//				city.cityNameShort = firstPartName[0];
				city.cityNameShort = entry.name;
				city.location = { lat:  lat, long: long};
//				city.osmId = entry.osm_id;
				city.osmId = 0;
//				for (let address of entry.address_components) {
//					if (address.types[0] == 'country') {
//						city.country = address.long_name;
//						city.country_code = address.short_name;
//					}
//				}
//				entry.formatted_address.slice(entry.formatted_address.lastIndexOf(',') + 1);
				city.country = entry.formatted_address.slice(entry.formatted_address.lastIndexOf(',') + 1);
				city.country_code = entry.formatted_address.slice(entry.formatted_address.lastIndexOf(',') + 1);
				
				this.searchResults.push(city);
				
//				console.log("*********************** RESULT ***********************");
//				console.log(city);
			}
			this.searchResultsLoading = false;
	    },

	    error => {
	        console.log('Log the error here: ', error);
	    });
	}
	
	editMarker(){
		this.loading = true;
		let _authKey = this.authKey;
		this.searchResults = [];
		//Setting Header
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
        headers = headers.append("Authorization", `Bearer ` + _authKey);
        
        if (this.addcityform.addcityModel.chosenDate == null) {
        	this.addcityform.addcityModel.chosenDate = "";
        }
        
		if (this.addcityform.addcityModel.description  == null) {
			this.addcityform.addcityModel.description = "";
		}	
		
		if (this.addcityform.addcityModel.todo  == null) {
			this.addcityform.addcityModel.todo = [];
		}
		if (this.addcityform.addcityModel.nottodo  == null) {
			this.addcityform.addcityModel.nottodo = [];
		}
		
		if (this.addcityform.addcityModel.photos  == null) {
			this.addcityform.addcityModel.photos = [];
		}
		
		if (this.addcityform.addcityModel.rating == null) {
			this.addcityform.addcityModel.rating = 0;
		}
		
        var marker = new Marker(this.addcityform.addcityModel.chosenDate, this.addcityform.addcityModel.cityname, 
				this.addcityform.addcityModel.cityNameShort, this.addcityform.addcityModel.country, 
				this.addcityform.addcityModel.description, this.addcityform.addcityModel.location.lat, 
				this.addcityform.addcityModel.location.long, this.addcityform.addcityModel.osmId, 
				this.addcityform.addcityModel.rating, /*this.addcityform.addcityModel.length*/ 0, 
				this.addcityform.addcityModel.user.id,
				this.addcityform.addcityModel.todo,
				this.addcityform.addcityModel.nottodo, this.addcityform.addcityModel.howlong,
				this.addcityform.addcityModel.planned, this.addcityform.addcityModel.country_code,
				this.addcityform.addcityModel.photos.length);
		
        marker.id = this.addcityform.addcityModel.id;
       
        console.log("call edit marker [" + marker.id + "]");
		this.httpClient.post<any>(`${this.serverGlobal}/api/edit-marker`, JSON.stringify(marker) ,{headers: headers})
		.subscribe(res => {
			console.log("marker id [" + res + "]");
			
			// delete ALL IMAGES at this point THEN SAVE
			this.servicePlace.deleteMarkerImages(this.addcityform.addcityModel.id).subscribe((res: number) => {
				console.log('PHOTOS CANCELLED NOW NEW');
				if (this.addcityform.addcityModel.photos != null) {
					for (let i=0; i < this.addcityform.addcityModel.photos.length; i++) {
						var photo = this.addcityform.addcityModel.photos[i];
						var name = res + "_" + i;
						var isMainImage = photo.isMain;
						
						this.servicePlace.uploadMarkerImages(photo.url, this.addcityform.addcityModel.id, isMainImage, name).subscribe((res: number) => {
//							 console.log('ok!');
					    },
					    error => {
					        console.log('error on uploading marker picture ['+ name +']: ', error);
					    });
					}
				}
		    },
		    error => {
		        console.log('error on deleting marker picture ['+ name +']: ', error);
		    });
			//SAVE IMAGES
			
			this.router.navigate(['/home']);
	    },
	
	    error => {
	        console.log('Log the error here: ', error);
	    });
	}
	addMarker(){
		this.loading = true;
		let _authKey = this.authKey;
		this.searchResults = [];
		//Setting Header
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
        headers = headers.append("Authorization", `Bearer ` + _authKey);
        
        if (this.addcityform.addcityModel.chosenDate == null) {
        	this.addcityform.addcityModel.chosenDate = "";
        }
        
		if (this.addcityform.addcityModel.description  == null) {
			this.addcityform.addcityModel.description = "";
		}	
		
		if (this.addcityform.addcityModel.todo  == null) {
			this.addcityform.addcityModel.todo = [];
		}
		if (this.addcityform.addcityModel.nottodo  == null) {
			this.addcityform.addcityModel.nottodo = [];
		}
		
		if (this.addcityform.addcityModel.photos  == null) {
			this.addcityform.addcityModel.photos = [];
		}
		
		if (this.addcityform.addcityModel.rating == null) {
			this.addcityform.addcityModel.rating = 0;
		}
		
        //TODO
        var marker = new Marker(this.addcityform.addcityModel.chosenDate, this.addcityform.addcityModel.cityname, 
				this.addcityform.addcityModel.cityNameShort, this.addcityform.addcityModel.country, 
				this.addcityform.addcityModel.description, this.addcityform.addcityModel.location.lat, 
				this.addcityform.addcityModel.location.long, this.addcityform.addcityModel.osmId, 
				this.addcityform.addcityModel.rating, /*this.addcityform.addcityModel.length*/ 0, 
				this.addcityform.addcityModel.user.id,
				this.addcityform.addcityModel.todo,
				this.addcityform.addcityModel.nottodo, this.addcityform.addcityModel.howlong,
				this.addcityform.addcityModel.planned, this.addcityform.addcityModel.country_code,
				this.addcityform.addcityModel.photos.length);
		
       
    	
		console.log("** marker");
		console.log(this.addcityform.addcityModel.country);
		console.log(this.addcityform.addcityModel);
		console.log("*********************** RESULT ***********************");
		
		console.log(JSON.stringify(marker));
		console.log("/** marker");
		
		
		
		this.httpClient.post<any>(`${this.serverGlobal}/api/marker`, JSON.stringify(marker) ,{headers: headers})
		.subscribe(res => {
			console.log("marker id [" + res + "]");
			
			
			//SAVE IMAGES
			if (this.addcityform.addcityModel.photos != null) {
				for (let i=0; i < this.addcityform.addcityModel.photos.length; i++) {
					var photo = this.addcityform.addcityModel.photos[i];
					var name = res + "_" + i;
					var isMainImage = photo.isMain;
					
//					console.log("marker id [" + res + "] [" + isMainImage + "] [" + name + "] [" + photo + "]");
					this.servicePlace.uploadMarkerImages(photo.url, res, isMainImage, name).subscribe((res: any []) => {
//						 console.log('ok!');
				    },
				    error => {
				        console.log('error on uploading marker picture ['+ name +']: ', error);
				    });
				}
			}
		
			this.router.navigate(['/home']);
	    },
	
	    error => {
	        console.log('Log the error here: ', error);
	    });
	}

	ngOnInit() {
		this.hideNewMarker=false;
		this.loading = false;
	    this.route.queryParams.subscribe(params => {
	        let id = params['id'];
	        if (params['id'] != null) {
				console.log("edit of " + id);
				this.loadCity(id);
				this.addcityform.addcityModel.id = id;
				this.selectCity(this.addcityform.addcityModel);
			}
	    });
	    
	    window.addEventListener("keyup", disableF5);
	    window.addEventListener("keydown", disableF5);
	    
	    function disableF5(e) {
	        if ((e.which || e.keyCode) == 116) e.preventDefault(); 
	    };
	}

	onPlannedChange(entry): void {
		console.log(entry);
		$(".starLabel").css("color","#ccc");
		$("#star"+entry+"Label").css("color","#ff4959");
		
		this.addcityform.addcityModel.planned = entry;
	}
	
	loadCity(id: number) {
	  this.servicePlace.getPlace(id)
	    .subscribe(res => {
	        	$(".custom-title").html(res.name_short);
	        	this.addcityform.addcityModel = new Addcity(res.name_short);
	  			
	        	// security check
	        	var currentUser = JSON.parse(localStorage.getItem('currentUser'));
	        	if (res.user.id != currentUser.id) {
	        		this.router.navigate(['/']);
	        	}
	        	
	  			this.addcityform.addcityModel.user = new User(res.user.id, res.user.name, res.user.name, null , null); 
	  			this.addcityform.addcityModel.user.avatar = res.user.avatar;
	  			
	  	    	this.addcityform.addcityModel.id = res.id;
	  	    	this.addcityform.addcityModel.cityNameShort = res.name_short;
	  	    	this.addcityform.addcityModel.osmId = res.google_city_id;
	  	    	
	  	    	this.addcityform.addcityModel.description = res.description;
	  	    	this.addcityform.addcityModel.rating = res.rating;

	  	    	this.addcityform.addcityModel.location = {lat: Number(res.location.lat), long: Number(res.location.long)};
	  	    	this.addcityform.addcityModel.country_code = res.country_short;
	  	    	this.addcityform.addcityModel.todo = [];
	  	    	this.addcityform.addcityModel.nottodo = [];
	  			
	  	    	for (let todo of res.todos) {
	  	    		if (todo.todo == 1) {
	  	    			this.addcityform.addcityModel.todo.push(new Tip(todo.text ,todo.like));
	  	    		} else {
	  	    			this.addcityform.addcityModel.nottodo.push(new Tip(todo.text ,todo.like));
	  	    		}
	  	    	}
	  	    	
	  	    	this.addcityform.addcityModel.isvisited = false;

        		this.addcityform.addcityModel.planned = res.planned;
	  	    	// controls
	  	        if (res.chosendate != null) {
	  	        	res.chosendate = res.chosendate.replace(/\-/g, '/'); // FIX IOS
	        		this.addcityform.addcityModel.chosenDate = res.chosendate;
	        		var year = new Date(res.chosendate).getFullYear();
	        		var month = (new Date(res.chosendate).getMonth() + 1);
	        		var monthText = ""; 
	        		if (month < 10) {
	        			monthText = "0" + month;
	        		} else {
	        			monthText = "" + month;
	        		}
	        		var day = new Date(res.chosendate).getDate();
	        		var dayText = ""; 
	        		if (day < 10) {
	        			dayText = "0" + day;
	        		} else {
	        			dayText = "" + day;
	        		}
	        		
	        		this.addcityform.addcityModel.chosenDate =   year + "-" + monthText + "-" + dayText;
	        		this.addcityform.addcityModel.dateVisited = res.chosendate;
	        		
	        		this.addcityform.addcityModel.howlong = res.howlong;
	        		
	  	  	      	if (new Date(res.chosendate) < new Date()) {
	  	  	      		this.addcityform.addcityModel.isvisited = true;
	  	  	      	} else {
						this.addcityform.addcityModel.isvisited = false;
	  	  	      	}
	  	        }
	  	        
		  	    this.servicePlace.getMarkerImages(id)
		  	    	.subscribe(images => {
	  	    		this.addcityform.addcityModel.photos = [];
	  	    		
	  	    		console.log("---------------------------------------------");
	  	    		console.log(images);
	  	    		console.log("---------------------------------------------");
	  	  		
			  	  	for (let image of images) {
		  	    		if (image.is_main_image == 1) {
	  	    				if (image.url_medium != null){
	  	    					this.addcityform.addcityModel.photos.push(new Photo(image.url_medium,image.is_main_image)); 
	  	    				} else {
	  	    					this.addcityform.addcityModel.photos.push(new Photo(image.url_image,image.is_main_image));
	  	    				}
	  	    			}
		  	    	}
		  	    	for (let image of images) {
		  	    		if (image.is_main_image != 1) {
		  	    			if (image.url_medium != null){
		  	    				this.addcityform.addcityModel.photos.push(new Photo(image.url_medium,image.is_main_image));  
	  	    				} else {
	  	    					this.addcityform.addcityModel.photos.push(new Photo(image.url_image,image.is_main_image));
	  	    				}
		  	    		}
		  	    	}
		  	    	
		  	    });
		  	    
	  	  		console.log(this.addcityform.addcityModel);
	        });
	}

  private SaveVisit(dto):boolean
  {
      return this.addcityService.postAddCity(dto);
  }
  //Function for Geographic Information
  private getGeographicData(){
    let locationArray;
    let location = this.searchElementRef.nativeElement.value;
    console.log(location);
    //Google variable
    var geocoder = new google.maps.Geocoder();
    var elevator = new google.maps.ElevationService;
    //Geocode function
    geocoder.geocode({ 'address': location }, (results, status) => {
      if (status === 'OK') {
        console.log(results);
        let countryLong, countryShort;
        // Formatted Address
        const formattedAddress = results[0].formatted_address;
        // Address Components
        const addressComponents = results[0].address_components;
        //Location Name
        const locationLong = addressComponents[0].long_name;
        //Set lat&lng for elevator
        locationArray = results[0].geometry.location;
        // Geometry
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        //for loop for country information
        for (let i=0; i < results[0].address_components.length; i++) {
          for (let j=0; j < results[0].address_components[i].types.length; j++) {
            if (results[0].address_components[i].types[j] == "country") {
              let country = results[0].address_components[i];
              const cLong = country.long_name;
              const cShort = country.short_name;
              countryLong = cLong;
              countryShort = cShort;
            }
          }
        }
        // Place ID
        const placeID = results[0].place_id;
        
        //Elevation function
        let elevate;
        elevator.getElevationForLocations({'locations': [locationArray]}, (results, status) => {
          if (status === 'OK') {
            // Retrieve the first result
            if (results[0]) {
              console.log('The elevation at this point is ' +
                  results[0].elevation + ' meters.');
                  //Get Elevation and Round
                  elevate = results[0].elevation;
                  elevate = Math.round(elevate);
            
            //Start Continent check
        
              //Country Variable
              let JsonCountries = countries
              let JsonCountryKeys = Object.keys(JsonCountries);
              //Continents Variable
              let JsonContinents = continents;
              let JsonContinentsKeys = Object.keys(JsonContinents);
              //Continent
              let continent: any;

              for (let j = 0; j < JsonCountryKeys.length; j++) {
                //  console.log(JsonKeys[j]);
                  let k = JsonCountryKeys[j];
                  let wholeCountryLine = JsonCountries[k];
                  let name = JsonCountries[k].name;
                  let cJcontinent = JsonCountries[k].continent;
                  let capital = JsonCountries[k].capital;
                  //If similiar, push info to array
                  if (JsonCountryKeys[j] == countryShort) {
                    let cJ = {
                      shortCount: k,
                      longCount: name,
                      continent: cJcontinent,
                      capital: capital
                    }
                    continent = cJcontinent;
                  }
                }
                  //Set Data for new Marker
                  this.newPlace = {
                    name: location,
                    location: {
                      lat: lat,
                      long: lng
                    },
                    google_city_id: placeID,
                    name_short: locationLong,
                    country_long: countryLong,
                    country_short: countryShort,
                    elevation: elevate,
                    continent: continent,
                    rating: this.rating,
                    /*visited: false,
                    planning: false,
                    date: this.date,
                    howLong: this.howLong,
                    tags: this.tags,
                    
                    */
                    };
            console.log(this.newPlace);
            //this.servicePlace.postPlace(this.newPlace);
            } else {
              console.log('No results found');
            }
          } else {
            alert('Elevation service failed due to: ' + status);
          }
        });

      //Geocoder else
      } else {
        alert('Geocode was not successful for the following reason: ' + status);
      }
    });
  }//getGeographicData finish
  
  
	roundNumber(current_count: number, count_max: number): number | null {
  		var num = current_count/count_max*10;
  		num = Math.round(num);
  		return num*10;
  	}
	
	freeVAN() {
		var total = 0;
		
		if (this.addcityform.addcityModel.description != "" && this.addcityform.addcityModel.description != null) {
			total++;
		}
		
		if (this.addcityform.addcityModel.planned == 1) {
			total++;
		}
		
		if ((this.addcityform.addcityModel.todo != null && this.addcityform.addcityModel.todo.length > 0) ||
				(this.addcityform.addcityModel.nottodo != null && this.addcityform.addcityModel.nottodo.length > 0)) {
			total++;
		}
		
		if ((this.addcityform.addcityModel.photos != null && this.addcityform.addcityModel.photos.length > 0)) {
			total++;
		}
		
		return total;
	}
	
	getIcon() {
		if (this.addcityform.addcityModel.planned == null || this.addcityform.addcityModel.planned == 1) {
			return this.markerIconPath;
		} else {
			return this.markerIconPathPlanned;
		}
	}
	
	changeType(type: string) {
		$(".list-type").removeClass('selected');
		$("#list-" + type).addClass('selected');
		
		this.type = type;
		
		console.log("type: " + this.type);
	}
}




