import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Place } from '../_models';
import {Location} from '@angular/common';

@Component({
  selector: 'app-searchtagsresults',
  templateUrl: './searchtagsresults.component.html',
  styleUrls: ['./searchtagsresults.component.css']
})
export class SearchtagsresultsComponent implements OnInit {

  public filteredPlaces:Place[];
  constructor(
    private location: Location,
    private route: ActivatedRoute) {
    }

  ngOnInit() {
    

    this.route.params.subscribe(params => {
      if(params != null && params.filteredplaces!= null){
        this.filteredPlaces = JSON.parse(params.filteredplaces);
      }
    });
  }

  public navigateBack(){
    this.location.back();
  }
}
