import { Component, OnInit, ElementRef, Input, ViewChild } from '@angular/core';
import { PicturesService } from '../../_services';
import { AddcityformComponent } from '../../addcityform/addcityform.component';
import { Router } from '@angular/router';
import { Photo } from '../../_models/photo';

class ImageSnippet {
	pending: boolean = false;
	status: string = 'init';

  	constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-addphotos',
  templateUrl: './addphotos.component.html',
  styleUrls: ['./addphotos.component.css']
})
export class AddphotosComponent implements OnInit {

  @Input() multiple: boolean = false;
  @ViewChild('fileInput') inputEl: ElementRef;
  public showMainPicture: boolean;
  public photos: Photo[];
  selectedFile: ImageSnippet;
  
  constructor(
    private servicePicutres: PicturesService,
    private addcityform: AddcityformComponent,
    private router: Router
  ) { 
	  this.selectedFile = new ImageSnippet(null, null);
  }

  ngOnInit() {
	  this.photos = this.addcityform.addcityModel.photos;
	  
//	  console.log("---------------------------------------------");
//		console.log(this.photos);
//		console.log("---------------------------------------------");
	  if (this.photos == null) {
		  this.photos = [];
	  }

	  if (this.photos.length > 0) {
		  this.showMainPicture = true;
	  }
  }
  
  handleFileInputOLD(files: FileList) {
//    var file = files.item(0);
    
	  Array.from(files).forEach(file => {
	      
		  var reader = new FileReader();
  	
//		  reader.addEventListener('load', (event: any) => {
//			  this.delay(3000).then(any=>{
//				  this.onSuccess();
//			  });
//		  });
   
		  this.showMainPicture = true;
  
  	
		  reader.readAsDataURL(file);
		  reader.onload = (_event) => { 
			  console.log("TEST START ------------------");
			  console.log(reader.result);
			  this.resizeImage(reader.result, 1000, 1000).then((result) => {
//  			console.log("THEN");
//  	    	console.log(result);
				  var isMain = false;
				  if (this.photos.length == 0) {
					  isMain = true;
				  }
  			
				  this.photos.push(new Photo(String(result),isMain)); 
				  console.log("TEST END ------------------ ");
			  });
		  }
	  });
  }
  
  handleFileInput(files: FileList) {
	    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB in bytes

	    Array.from(files).forEach(async (file) => {
	        if (file.size > MAX_FILE_SIZE) {
	            console.error(`File too large: ${file.name} is ${(file.size / 1024 / 1024).toFixed(2)} MB, exceeding the 10 MB limit.`);
	            return;
	        }

	        try {
	            const reader = new FileReader();
	            reader.readAsDataURL(file);
	            
	            reader.onload = async (_event) => {
	                this.showMainPicture = true;
	                const result = await this.resizeImage(reader.result as string, 1000, 1000);
	                
	                const isMain = this.photos.length === 0;
	                this.photos.push(new Photo(String(result), isMain));
	            };
	            
	            reader.onerror = (error) => {
	                console.error("Error reading file:", error);
	            };
	        } catch (error) {
	            console.error("Error processing file:", error);
	        }
	    });
	}
  
  resizeImage(base64Str: string, maxWidth: number = 400, maxHeight: number = 350): Promise<string> {
	    return new Promise((resolve, reject) => {
	        let img = new Image();
	        img.src = base64Str;

	        img.onload = () => {
	            // Create a canvas and context
	            let canvas = document.createElement('canvas');
	            let ctx = canvas.getContext('2d');

	            if (!ctx) {
	                reject(new Error('Unable to get canvas context'));
	                return;
	            }

	            // Calculate new dimensions
	            let width = img.width;
	            let height = img.height;
	            const aspectRatio = width / height;

	            if (width > maxWidth) {
	                width = maxWidth;
	                height = width / aspectRatio;
	            }

	            if (height > maxHeight) {
	                height = maxHeight;
	                width = height * aspectRatio;
	            }

	            // Set canvas dimensions and draw the image
	            canvas.width = width;
	            canvas.height = height;
	            ctx.drawImage(img, 0, 0, width, height);

	            // Use image/jpeg format with a quality parameter for better compression
	            resolve(canvas.toDataURL('image/jpeg', 0.7));
	        };

	        img.onerror = (error) => {
	            reject(new Error('Failed to load image: ' + error.message));
	        };
	    });
	}


  resizeImageOLD(base64Str, maxWidth = 400, maxHeight = 350) {
	  return new Promise((resolve) => {
	    let img = new Image()
	    img.src = base64Str
	    img.onload = () => {
	      let canvas = document.createElement('canvas')
	      const MAX_WIDTH = maxWidth
	      const MAX_HEIGHT = maxHeight
	      let width = img.width
	      let height = img.height

	      if (width > height) {
	        if (width > MAX_WIDTH) {
	          height *= MAX_WIDTH / width
	          width = MAX_WIDTH
	        }
	      } else {
	        if (height > MAX_HEIGHT) {
	          width *= MAX_HEIGHT / height
	          height = MAX_HEIGHT
	        }
	      }
	      canvas.width = width
	      canvas.height = height
	      let ctx = canvas.getContext('2d')
	      ctx.drawImage(img, 0, 0, width, height)
	      resolve(canvas.toDataURL())
	    }
	  })
	}
  
  processFile(imageInput: any) {
	    const file: File = imageInput.files[0];
	    const reader = new FileReader();

	    reader.addEventListener('load', (event: any) => {

		      this.selectedFile = new ImageSnippet(event.target.result, file);
	
		      this.selectedFile.pending = true;
	      
		    this.delay(3000).then(any=>{
			    this.onSuccess();
			});

	    });
	    
		this.showMainPicture = true;
	    

	    reader.readAsDataURL(file);
	    reader.onload = (_event) => { 
//	    	console.log("TEST START ------------------");
	    	console.log(reader.result);
	    	this.resizeImage(reader.result, 1000, 1000).then((result) => {
//	    		console.log("THEN");
//		    	console.log(result);
	    		var isMain = false;
	  			if (this.photos.length == 0) {
	  				isMain = true;
	  			}
	  			
	  		    this.photos.push(new Photo(String(result),isMain)); 
//	    	    console.log("TEST END ------------------");
	    	});
	    	
	    	
	    	
	    	
	      }
	}
  
  
  async delay(ms: number) {
	await new Promise(resolve => setTimeout(()=>resolve(), ms)).then(()=>console.log("fired"));
  }

	private onSuccess() {
	    this.selectedFile.pending = false;
	    this.selectedFile.status = 'ok';
	}
	
	private onError() {
	    this.selectedFile.pending = false;
	    this.selectedFile.status = 'fail';
	    this.selectedFile.src = '';
	}
  
  deletePhoto(photo : Photo) {
	  this.photos.splice(this.photos.indexOf(photo), 1);
	  
	  if (this.photos.length == 0) {
		  this.showMainPicture = false;
	  }
  }
  
	addPhotos(){
		this.addcityform.addcityModel.photos = this.photos;
		this.router.navigate(['/addcity']);
	}
}



  





