import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../_models';
import { FormControl } from '@angular/forms';
import { SearchService, AuthenticationService } from '../_services';
import { Place } from '../_models/place';
import 'rxjs/add/observable/empty' ;
import {Router } from '@angular/router';

@Component({
  selector: 'app-searchplaces',
  templateUrl: './searchplaces.component.html',
  styleUrls: ['./searchplaces.component.css']
})
export class SearchplacesComponent implements OnInit {
    //Variables
	public searchResults: Observable<Place[]>;
    private isLoading: boolean;
    public searchField: FormControl;

  constructor(
    private serviceSearch: SearchService,
    private serviceAuthentication: AuthenticationService,
    private router:Router
  ) { }

    ngOnInit() {
      this.searchField = new FormControl();
      this.searchResults = this.searchField.valueChanges
      .debounceTime(400)
      .distinctUntilChanged()
      .do( () => this.isLoading = true)
      .switchMap(term => this.onSearchChange(term))
      .do( () => this.isLoading = false);
    }

    onSearchChange(searchValue : string ): Observable<Place[]> {  
      if(searchValue != null && searchValue !=''){
        var temp = this.serviceSearch.searchPlacesResultObservable(searchValue);
        return temp;
      }
      return Observable.of([]);
    }

}
