import { Injectable } from '@angular/core';
import { AuthenticationService } from '.';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedService {

   //Variables
   private authKey: string;
  
   constructor(private httpClient: HttpClient,
     private serviceAuthentication: AuthenticationService) {
       //Set authKey
       this.authKey =  this.serviceAuthentication.getToken();
   }
 
 //Server URL Variable
 private serverGlobal: string = environment.apiUrl;

  getLastTenFeed(): Observable<any /*Place[]*/>{
    let _authKey = this.authKey;
    //Setting Header
    let headers = new HttpHeaders();
    headers = headers.append("Authorization", `Bearer ` + _authKey);
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
    //Get Request
    var temp$ =  this.httpClient.get<any/*Place[]*/>(`${this.serverGlobal}:80/api/v1/feed/10`, {headers: headers});
    
    temp$.subscribe(item => console.log(item));
    //todo replace following line
    return new Observable();
  } 
}
