﻿export class User {
    id:number;
    pw:string;
    firstName:string;
    lastName:string;
    username:string;
	avatar: string;
	bio: string;
	referral: string;
	referral_used: number;
	markers:number;
	badges_count:number;
	places_count:number;
	badges;

    constructor(id, username, first, pw, last) {
        this.id = id;
        this.firstName = first;
        this.lastName = last;
        this.username = username;
        this.pw = pw;
      }
}