import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
//Google Maps Import Angular 6+
/// <reference types="googlemaps" />
import { MapsAPILoader } from '@agm/core';
import { TodoNottodoService } from '../../_services';
import { countries, languages, continents} from 'countries-list';
import { AddcityformComponent } from '../../addcityform/addcityform.component';
import { Tip } from '../../_models';
import { Router } from '@angular/router';

declare var google: any;




@Component({
  selector: 'app-addthingstodo',
  templateUrl: './addthingstodo.component.html',
  styleUrls: ['./addthingstodo.component.css']
})
export class AddthingstodoComponent implements OnInit {
	public tips: Tip[];
	public tip: string;
	
	constructor(private mapsAPILoader: MapsAPILoader, 
	private router: Router,
	private ngZone: NgZone,
	private addcityform: AddcityformComponent,
	private todoNottodoService: TodoNottodoService) { 
	  this.tips = this.addcityform.addcityModel.todo;
	  
	  if (this.tips == null) {
		  this.tips = [];
	  }
	}
	
	//Reference to Autocomplete Search
	@ViewChild("search")
	public searchElementRef: ElementRef;
	
	public latitude: number;
	public longitude: number;
	public zoom: number;
	
	ngOnInit() {
	}
	
	addTips() {
		this.addcityform.addcityModel.todo = this.tips;
		this.router.navigate(['/addcity']);
	}
	
	addTip() {
		this.tips.push(new Tip(this.tip, 0));
		this.tip = "";
	}
	
	deleteTip(tip : Tip) {
		this.tips.splice(this.tips.indexOf(tip), 1);
	}
}
