import { Injectable, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';

import { catchError, map, retry } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { User } from '../_models';
import 'rxjs/Rx';
import { Place } from '../_models/place';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  //Reference to Search
  @ViewChild("search")
  public searchElementRef: ElementRef;

  //Variables
  private authKey: string;

  //Server URL Variable
  private serverGlobal: string = environment.apiUrl;

  private search: Observable<any/*search*/>

  constructor(private httpClient: HttpClient,
              private serviceAuthentication: AuthenticationService) {
                //Set authKey
                this.authKey =  this.serviceAuthentication.getToken();
 }

	searchResult(term: string, type: string, offset: number) {
		let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    
	    if (offset == null) {
	    	offset = 0;
	    }

	    return this.httpClient.get<any>(`${environment.apiUrl}/api/search?q=${term}&type=${type}&offset=${offset}`, {headers: headers});
	}

    // Search for User/Buddies

    //Working part
      searchBuddiesResult(term: string):Observable<any> {
      let _authKey = this.authKey;
      //Setting Header
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
      headers = headers.append("Authorization", `Bearer ` + _authKey);
      //Get Request
      return this.httpClient.get<any/*User[]*/>(`${this.serverGlobal}/api/v1/user/search?q=${term}`, {headers: headers})
      .pipe(catchError(error => {
        return throwError("Something went wrong " + error);
      }));
      
    } // searchBuddies function

     // Search for User/Buddies
    searchBuddiesResultObservable(term: string): Observable<User[]> {
      let _authKey = this.authKey;
      //Setting Header
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
      headers = headers.append("Authorization", `Bearer ` + _authKey);
      //Get Request
      let apiUrl = `${this.serverGlobal}:80/api/v1/user/search?q=${term}`;
       return this.httpClient.get<User[]>(apiUrl, {headers: headers});
    }
     // searchBuddies function


      // Search for Places
  searchPlacesResult(term: string) {
    let _authKey = this.authKey;
    //Setting Header
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
    headers = headers.append("Authorization", `Bearer ` + _authKey);
    //Get Request
    return this.httpClient.get<any/*Places[]*/>(`${this.serverGlobal}:80/api/v1/user/search?q=${term}`, {headers: headers});
  }

  searchPlacesResultObservable(term: string):Observable<Place[]> {
    let _authKey = this.authKey;
    //Setting Header
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
    headers = headers.append("Authorization", `Bearer ` + _authKey);
    //Get Request

    let place1 = new Place(1, "Genf");
    let place2 = new Place(2, "Zurich");

    let placeList = [place1, place2];
    return Observable.of(placeList);
    

    //return this.httpClient.get<Place[]>(`${this.serverGlobal}:80/api/v1/place/search?q=${term}`, {headers: headers});
  }


  // searchPlaces function

  // Search for Tags
  searchTagsResult(term: string) {
    let _authKey = this.authKey;
    //Setting Header
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', 'http://localhost:4200/');
    headers = headers.append("Authorization", `Bearer ` + _authKey);
    //Get Request
    return this.httpClient.get<any/*Tags[]*/>(`${this.serverGlobal}:80/api/v1/user/search?q=${term}`, {headers: headers});
  } // searchTags function

}