import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
//Google Maps Import Angular 6+
/// <reference types="googlemaps" />
import { MapsAPILoader } from '@agm/core';
import { User } from '../_models';
import { Tip } from '../_models';
import { Addcity } from '../_models';
import { BuddiesService } from '../_services';
import { PlacesService, UserService } from '../_services';
import { countries, languages, continents} from 'countries-list';

declare var google: any;

@Component({
  selector: 'app-personprofile',
  templateUrl: './personprofile.component.html',
  styleUrls: ['./personprofile.component.css']
})
export class PersonprofileComponent implements OnInit {
	public addMarkerIconPath:any = "assets/icon.png";
  	currentUser: User;
	loggedUser: User;
	public isFriend: boolean;
	public isRequested: boolean;
	public relationshipId: number;
	public markerIconPath:any = {
        url: 'assets/icon_pin.png',
        scaledSize: {
          width: 25,
          height: 35
        }};
	public markerIconPathPlanned:any = {
        url: 'assets/icon_pin_planned.png',
        scaledSize: {
          width: 25,
          height: 35
        }};
	public city: Addcity;
  users: User[] = [];
  public profilePrivacy: boolean;
  public bio: string;
  public currently: string;
  public nextDestination: string;
  private user: any;
  public latitude: number;
  public longitude: number;
  public zoom: number;
  //Variable for Places
  public places = [] /*Marker*/;
  
  public loading: boolean;
  public markersLoading: boolean;
  public nobuddies: boolean;
  
  public markers = [];
  public offset: number;

  constructor(private mapsAPILoader: MapsAPILoader, 
    private ngZone: NgZone,
    private route: ActivatedRoute, private router: Router,
    private buddyService: BuddiesService,
    private servicePlace: PlacesService,
    private userService: UserService) {
      	this.profilePrivacy = true;
      
     }

  	ngOnInit() {
  		this.loading = false;
  		this.markersLoading = false;
    	this.offset = 0;
  		// are you logged?
  		if (JSON.parse(localStorage.getItem('currentUser')) == null) {
  			this.router.navigate(['/login'], { queryParams: {error: 'You should be logged in to watch this profile'}});
		}
  		
  		this.route.params.subscribe(
  				params => {
  					let id = +params['id'];
  					this.loadAllUsers(id);
  					
  					var buddyDetail = this.buddyService.getBuddyByUserId(id).subscribe(res=> {
	  	    			var user = new User(res.id,res.name,"name","pw","surname");
	  	    			if (res.avatar != null && res.avatar != "") {
							user.avatar = res.avatar;
						}
	  	    			if (res.bio != null && res.bio != "") {
	  	    				user.bio = res.bio;
	  	    			} else {
	  	    				user.bio = "";
	  	    			}
	  	    			if (res.badges_count == null) {
	  	    				res.badges_count = 0;
	  	    			}
	  	    			
	  	    			if (res.places_count == null) {
	  	    				res.places_count = 0;
	  	    			}
	  	    			
	  	    			user.badges_count = res.badges_count;
	  	    			user.places_count = res.places_count;
	  	    			user.badges = res.badges;
	  	    			
	  	    			
	  	    			this.currentUser = user;
	  				    // load markers
	  	    			this.markers = [];
	  				    this.loadUserMarkes();
	  	    			
	  	    			
	  	    			// check if the buddy is our friend
	  	    			this.loggedUser = JSON.parse(localStorage.getItem('currentUser'));
	  	    			this.isFriend = null;
	  	    			this.buddyService.getBuddies().subscribe(res=>{
	  	    				this.isFriend = false;
	  	    				for (let buddy of res) {
	  	    					if (buddy.id == this.currentUser.id) {
	  	    						this.isFriend = true;
	  	    						this.relationshipId = buddy.id;
	  	    						break;
	  	    					}
	  	    				}
	  	    				
	  	    				// check if we requested it
	  	    				this.buddyService.getBuddyRequested(this.currentUser.id).subscribe(requests=>{
	  	    					if (requests.length > 0) {
	  	    						this.isRequested = true;
	  	    					} else {
	  	    						this.isRequested = false;
	  	    					}
	  	    				});
	  	    				 
	  	    	  		});
	  	    			
	  	    		});
  					
  					//Get all places from the Database
  					this.servicePlace.getPlacesByUserId(id)
  					//Save observable in places variable
	  					.subscribe(
	  					(data: any [] /*places[]*/) => {
	  					/*console.log(data));*/
		  					this.places = data;
		  					//TODO here check places! this.places
		  					console.log(this.places);
		  					for (let place of this.places) {
		  						if (place.planned == 0) {
		  							place.marker = this.markerIconPathPlanned;
		  						} else {
		  							place.marker = this.markerIconPath;
		  						}
		  				    }
		  					this.makeNumber();
  					});

  					  //Set Google Maps defaults
  					this.zoom = 12;
  					this.latitude = 47.36667;
  					this.longitude = 8.55;

  					//set current position
  					this.setCurrentPosition();	
  	    	     });
  }

//  addBuddy(){
//    this.buddyService.addBuddy(this.user);
//  }
  
  	addBuddy(id: number) {
		console.log("id: " + id);
		this.isFriend = true;
		this.buddyService.addBuddy(id)
		.subscribe(res=> {
		});
	}
  	
  	deleteBuddy(id: number) {
  		console.log("reject buddy with relationship ["+ id + "] ");
  		this.isFriend = false;
  		this.userService.declineBuddy(id).subscribe(res=> {
		});
  	}
  	
  	private loadUserMarkes() {
		this.markersLoading = true;
		this.servicePlace.searchPlacesByUserId(this.currentUser.id, this.offset)
		.subscribe(
		(data: any []) => {
			for (let marker of data) {
				if (marker.user_avatar == null || marker.user_avatar == "") {
					marker.user_avatar = '/assets/avatar.png';
				}
				
				if (marker.chosendate != null && marker.chosendate != "") {
					var datestring = marker.chosendate.substring(0, 10);
					marker.chosendate = datestring;
				}
				this.markers.push(marker);
		    }
			
			this.markersLoading = false;
		});
	}
	
	loadMoreResult() {
		this.offset += 10;
		this.loadUserMarkes();
	}
  
	private loadAllUsers(id: number) {
		this.nobuddies = true;
    	this.loading = true;
		console.log("**** enter in loadAllBuddies");
		this.users = [];
		
		this.buddyService.getBuddiesOfAUserByUserId(id).subscribe(res=> {
			if(res == null)
				return;

			for (let buddy of res) {
				var user = new User(buddy.id,buddy.name,"name","pw","surname");
				if (buddy.avatar != null && buddy.avatar != "") {
					user.avatar = buddy.avatar;
				}
				user.markers = buddy.last_markers;
				
				this.users.push(user);
			}
			this.nobuddies = false;
  		})
		.add(() => {
			this.loading = false;
		});
		
//		this.buddyService.getBuddiesOfAUserByUserId().subscribe(res=>{
//			if(res == null)
//				return;
//			
//			let buddyIdList = [];
//			for (let buddy of res) {TODO
//			var buddyDetail = this.buddyService.getBuddy(buddy.buddy_id).subscribe(res=> {
//				var user = new User(res.id,res.name,"name","pw","surname");
//					console.log(user.username);
//					this.users.push(user);
//				});
//			}
//		});
	}
	
	private truncate(input) {
	   if (input != null && input.length > 32) {
	      return input.substring(0, 32) + '...';
	   }
	   return input;
	};
	
    public getCityDetail(place) {
    	console.log(place);

    	this.city = new Addcity(place.name);
    	this.city.id = place.id;
    	this.city.cityNameShort = place.name_short;
    	this.city.description = this.truncate(place.description);
    	this.city.rating = place.rating;
    	this.city.planned= place.planned;
    	this.city.todo = [];
    	this.city.nottodo = [];
    	
    	for (let todo of place.todos) {
    		if (todo.todo == 1) {
    			this.city.todo.push(new Tip(todo.text ,todo.like));
    		} else {
    			this.city.nottodo.push(new Tip(todo.text ,todo.like));
    		}
    	}
    	
    	// controls
        if (place.chosendate != null) {
  	      var month = new Array();
  	      month[0] = "January";
  	      month[1] = "February";
  	      month[2] = "March";
  	      month[3] = "April";
  	      month[4] = "May";
  	      month[5] = "June";
  	      month[6] = "July";
  	      month[7] = "August";
  	      month[8] = "September";
  	      month[9] = "October";
  	      month[10] = "November";
  	      month[11] = "December";
  	      place.chosendate = place.chosendate.replace(/\-/g, '/'); // FIX IOS
  	      this.city.month = month[new Date(place.chosendate).getMonth()];
  	      this.city.year = new Date(place.chosendate).getFullYear();
  	      this.city.chosenDate = place.chosendate;
        }
    	
    }

  private makeNumber(){
    for (let index = 0; index < this.places.length; index++) {
      //Make the Location Data from Strings to Numbers
      this.places[index].location.lat = +this.places[index].location.lat;
      this.places[index].location.long = +this.places[index].location.long;
      this.places[index].elevation = +this.places[index].elevation;
      }
  }

  //If Geolocation active --> set actual coordinates as center of map
  public setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
      	// TODO doesn't work very well
//      	console.log("Geolocation is active latitude ["+position.coords.latitude+"] longitude ["+position.coords.longitude+"]");
//        this.latitude = position.coords.latitude;
//        this.longitude = position.coords.longitude;
//        this.zoom = 12;
      });
    }
  }//setCurrentPosition    

}
