export class VM_UserProfile {
    id:number;
    pw:string;
    firstName:string;
    lastName:string;
    username:string;
    description:string;
    currentlyIn:string;
    nextDestination:string;
    planning:string;
    visited:string;
    email:string;
    firstAndLastname:string;

    constructor(id,pw, first, last, user,desc, currently, next, planning, visited, email) {
        this.id = id;
        this.firstName = first;
        this.lastName = last;
        this.username = user;
        this.pw = pw;
        this.description = desc,
        this.currentlyIn = currently;
        this.nextDestination = next;
        this.planning = planning;
        this.visited = visited;
        this.email = email;
        this.firstAndLastname = first + " " + last;
      }
}