import { User } from ".";
import { Place } from ".";

export class Search {
	id: number;
    user: User;
	added: boolean; // if it's a user, this should contain a value
	place: Place;
	tag: string; // if it's a tag, this should contain a value
    type: string; // "tag" / "user" / "place"
	number: number; // if it's a tag or a place, this should contain a value
	picture: string; // if it's a tag or a place, this should contain a value

	constructor(id, user, added, place, tag, type, number, picture) {
	    this.id = id;
	    this.user = user;
	    this.added = added;
	    this.place = place;
	    this.tag = tag;
	    this.type = type;
	    this.number = number;
	    this.picture = picture;
	}
}