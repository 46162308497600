import { User } from ".";
import { Place } from ".";

export class Feedable {
	id: number;
    type: string; // "photo" / "planned" / "visited" / "todo" / "nottodo"
    text: string;
    pictures: string[];
	user: User;
	when: string;
	place: Place;

    constructor(id, type, text, pictures, user, when, place) {
        this.id = id;
        this.type = type;
        this.text = text;
        this.pictures = pictures;
        this.user = user;
        this.when = when;
        this.place = place;
    }
}