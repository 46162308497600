import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { UserService } from '../_services';
import { AuthenticationService } from '../_services';

import { ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

@Component({
  selector: 'app-buyvan',
  templateUrl: './buyvan.component.html',
  styleUrls: ['./buyvan.component.css']
})
export class BuyvanComponent implements OnInit {
	public currentUser: User;
	public loading: boolean;
	public imageSrc: string;
	public file: File;
	public showSaveAvatar: boolean = false;

	constructor(private userService: UserService,
			private router: Router,
			private serviceAuthentication: AuthenticationService) {
		
	}


	ngOnInit() {
		console.log(localStorage.getItem('currentUser'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        
	}
	
	clipboard(value) {
	    document.addEventListener('copy', (e: ClipboardEvent) => {
	      e.clipboardData.setData('text/plain', (value));
	      e.preventDefault();
	      document.removeEventListener('copy', null);
	    });
	    document.execCommand('copy');
	  }

}
