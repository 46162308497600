import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BuddiesService {

  //Server URL Variable
  private serverGlobal: string = environment.apiUrl;

  //Variables
  private authKey: string;

  constructor(private httpClient: HttpClient,
      private serviceAuthentication: AuthenticationService) {
      //Set authKey
      this.authKey =  this.serviceAuthentication.getToken();
  }
  
  // Get all User 
  getUsers() {
    let _authKey = this.authKey;
    //Setting Header
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
    headers = headers.append("Authorization", `Bearer ` + _authKey);
    //Get Request
    return this.httpClient.get<any/*User[]*/>(`${this.serverGlobal}/api/v1/users`, {headers: headers});
  } // Get Users function

  //Get all Buddies
  getBuddies() {
	//Set authKey
	this.authKey =  this.serviceAuthentication.getToken();
    let _authKey = this.authKey;
    //Setting Header
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
    headers = headers.append("Authorization", `Bearer ` + _authKey);
    //Get Request
    var request = this.httpClient.get<any>(`${environment.apiUrl}/api/buddy`, {headers: headers});
    //check response is ok
    //validation

    return request;
  } // Get Buddies function
  
  addBuddy(buddyId){
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    var request = this.httpClient.post<any>(`${environment.apiUrl}/api/buddy`, { id: buddyId }, {headers: headers});
        return request;
	  } 

//  getBuddyRequests() {
//    let _authKey = this.authKey;
//    //Setting Header
//    let headers = new HttpHeaders();
//    headers = headers.append('Content-Type', 'application/json');
//    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
//    headers = headers.append("Authorization", `Bearer ` + _authKey);
//    //Get Request
//    var request = this.httpClient.get<any/*User[]*/>(`${this.serverGlobal}/api/v1/buddy/requests`, {headers: headers});
//    //check response is ok
//    //validation
//
//    return request;
//  } 
  
  getBuddyRequested(buddyId) {
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    //Get Request
	    var request = this.httpClient.get<any/*User[]*/>(`${this.serverGlobal}/api/buddy/requested/`+buddyId, {headers: headers});
	    //check response is ok
	    //validation

	    return request;
  } 
  
  getBuddyByUserId(buddyId){
    let _authKey = this.authKey;
    //Setting Header
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
    headers = headers.append("Authorization", `Bearer ` + _authKey);
    //Get Request
    var t = this.httpClient.get<any/*User[]*/>(`${environment.apiUrl}/api/user/`+buddyId, {headers: headers});
    return t;
  } 
  
  // get the buddies of a user
  getBuddiesOfAUserByUserId(userId){
	let _authKey = this.authKey;
	//Setting Header
	let headers = new HttpHeaders();
	headers = headers.append('Content-Type', 'application/json');
	headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	headers = headers.append("Authorization", `Bearer ` + _authKey);
	//Get Request
	var t = this.httpClient.get<any/*User[]*/>(`${environment.apiUrl}/api/buddy-by-user/`+userId, {headers: headers});
	return t;
	}
  
  getBuddy(buddyId){
    let _authKey = this.authKey;
    //Setting Header
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
    headers = headers.append("Authorization", `Bearer ` + _authKey);
    //Get Request
    var t = this.httpClient.get<any/*User[]*/>(`${environment.apiUrl}/api/buddy/`+buddyId, {headers: headers});
    return t;
  } 
  
  //Block Buddy 
  blockBuddy(user){
    let _user = user;
    let _authKey = this.authKey;
    //Setting Header
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
    headers = headers.append("Authorization", `Bearer ` + _authKey);
    //Post Request
    this.httpClient.post<any/*User*/>(`${this.serverGlobal}:80/api/v1/buddy/9/decline`, _user.email, {headers: headers})
                                .subscribe((_user:any) => {
                                  console.log(_user);
                                });
  } // Block Buddies function

  // Decline Buddy request
  declineBuddy(user){
    let _user = user;
    let _authKey = this.authKey;
    //Setting Header
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
    headers = headers.append("Authorization", `Bearer ` + _authKey);
    //Post Request
    this.httpClient.post<any/*User*/>(`${this.serverGlobal}:80/api/v1/buddy/9/decline`, _user.email, {headers: headers})
                                .subscribe((_user:any) => {
                                  console.log(_user);
                                });
  } // Decline Buddies function

}

//User Interface
interface User {
  id: number,
  email: string,
  firstname: string,
  lastname: string,
  avatar: string,
  privacy: "Public" | "Private",
  bio: string,
  currently: string,
  next_destination: string
}
