import { Injectable } from '@angular/core';
import { CityDetail, Tip } from '../_models';
import { Place } from "../_models";
import {Comment} from '../_models';

@Injectable({
  providedIn: 'root'
})
export class CitydetailService {

  constructor() { }

//  getCityDetailById(id:string):CityDetail{
//
//    // instantiate webservice call
//
//    //call webservice cityDetailById
//
//    //check result
//    
//    //if not ok: show errorMessage 
//
//    //log
//
//    //if all ok: return cityDetailObject
//    let listPlace = [new Place(1,"Marokko")];
//    let listComments = [ new Comment(1,"Lorenzo","Giordano","/assets/avatar.png","Amazing place, I really want to go there as well!")];
//    let listNotTodo = [new Tip("dont go to McDonalds",5)];
//    let listTodo = [new Tip("Go To the Züri Zoo", 5)];
//    let listPhotos = ["https://www.bain.com/contentassets/79711aa9279e4b1281b4d355ccb057f8/local-office-images-zurich-1950x650.jpg", "https://img.theculturetrip.com/768x432/wp-content/uploads/2017/07/shutterstock_1012854337.jpg"];
//
//    return {chosenDate:Date.now().toString(),chosenTagList: listPlace,cityname:"Zurich",comments:listComments,dateVisited:new Date('01 Jan 1970 00:00:00 GMT')
//    ,howlong:7,isvisited:false,location:{lat:232,long:2323},nottodo:listNotTodo,photos:listPhotos,rating:5,selectedFile:"selectedFileStringTest"
//    ,todo:listTodo,user:null}
//  }
}
