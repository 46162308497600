import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';

//Google Maps Import Angular 6+
/// <reference types="googlemaps" />


@Component({
  selector: 'app-searchplaceresult',
  templateUrl: './searchplaceresult.component.html',
  styleUrls: ['./searchplaceresult.component.css']
})
export class SearchplaceresultComponent implements OnInit {

  constructor() { }

    public latitude: number;
    public longitude: number;
    public zoom: number;

  ngOnInit() {

    //Set Google Maps defaults
    this.zoom = 12;
    this.latitude = 47.187344;
    this.longitude = 8.51409;



  }

}
