﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map,mapTo,tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IfStmt } from '@angular/compiler';
import { first } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class AuthenticationService {

    //UserData
    private grant_type: string = 'password';
    private client_id: number = 3;
    private client_secret: string = '2vf2WuhlF8ecgGszUdVT7yQuo5lDfe8IQtXiuwKr';
    private username: string = 'daniel.weber@vanderon.ch';
    private password: string = 'Pa$$w0rd';
    private scope: string = '';
    private bearer_token: string;

    public loginData = {
        "grant_type": this.grant_type,
        "client_id": this.client_id,
        "client_secret": this.client_secret,
        "username": this.username,
        "password": this.password,
        "scope": this.scope
    }

    //Server URL Variable
    private serverGlobal: string = environment.apiUrl;

    constructor(private http: HttpClient) { }
        //Method to Store Token
        public storeToken(token: string){
            localStorage.setItem("token", token);
        }

        //Get Token Method
        public getToken(){
            var token = localStorage.getItem("token");

            //todo check if token is ok
            // if(token == null){
            //     //--> go to Login page 
            // }
            return token;
        }

        //Remove Token Method
        public removeToken(){
            return localStorage.removeItem("token");
        }

    login(username: string, password: string) {
    	localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
    	
    	this.loginData.username = username;
    	this.loginData.password = password;
    	
    	const data = this.loginData;
        //Setting Header
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);

        return this.http.post<any>(`${this.serverGlobal}/oauth/token`, data, {headers: headers})
            .pipe(
        		map((data:any) => {
                this.bearer_token = data.access_token;
                this.storeToken(this.bearer_token);

        		return this.bearer_token;
    		})
      );
    }
    
    getUser() { 
    	let headers = new HttpHeaders();
        const httpOptions = {
		  headers: new HttpHeaders({
		    'Content-Type':  'application/json',
		    'Authorization':  'Bearer ' + this.bearer_token,
		    'observe': 'response' 
		  })
		};

		return this.http.get(`${this.serverGlobal}/api/user`, httpOptions).pipe(map((response:any) => {
			return JSON.stringify(response);
		})); 
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
    }



    

}