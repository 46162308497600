import { Tip } from ".";
import { User } from ".";
import { Comment } from ".";
import { Photo } from ".";

/*

{
	"chosendate": "2020-06-30",
	"cityNameShort": "Castile and Le�n",
	"cityname": "Castile and Le�n, Spain",
	"country": "Spain",
	"description": "descr",
	"location": {"lat": "41.8037172", "long": "-4.7471726"},
	"nottodo": [{"description": "ceasdasd", "likes": 0}],
	"length": 1,
	"osmId": 349041,
	"rating": 5,
	"todo": [{"description": "werwer", "likes": 0}],
	"user": {"id": 12, "name": "lorenzo.giordano", "email": "lorenzo.giordano@live.it", "api_token": null, "created_at": "2019-11-30 13:29:00"}

	}
*/

export class Marker {
	public id: number;
	public chosendate: string;
    public cityname: string;
	public cityNameShort: string;
	public country: string;
	public country_code: string;
	public description: string;
	public location: {
		lat: number,
	    long: number
	};
	public osmId: number;
	public rating: number;
	public length: number;
	public todo: Tip[];
    public nottodo: Tip[];
	public user: User;
	public howlong;
	public planned;
	public comments: Comment[];
	public image: number;
	
    constructor(chosendate, cityname, cityNameShort, country, description, lat, long, osmId, rating, length, userId, todo, nottodo, howlong, planned, country_code, image) {
    	this.chosendate = chosendate;
    	this.cityname = cityname;
    	this.cityNameShort = cityNameShort;
    	this.country = country;
    	this.description = description;
    	let location: { lat: number, long: number } = {} as { lat: number, long: number };
    	this.location = location;
    	this.location.lat = lat;
    	this.location.long = long;
    	this.osmId = osmId;
    	this.rating = rating;
    	this.length = length;
    	var user = new User(userId, "", "", "", "");
    	this.user = user;
    	
    	this.todo = todo;
    	this.nottodo = nottodo;
    	
    	if (howlong != null) {
    		this.howlong = howlong;
    	} else {
    		this.howlong = "";
    	}
    	
    	this.image = image;
    	this.planned = planned;
    	this.country_code = country_code;
    }
}