import { Component, OnInit } from '@angular/core';
import { VM_UserProfile } from './vm_userprofile';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.css']
})
export class EditprofileComponent implements OnInit {
  public userProfile:VM_UserProfile;
  constructor() { }

  ngOnInit() {
    this.loadUserProfile();
  }
  loadUserProfile(){
    this.userProfile = new VM_UserProfile(1,"pw","Gustav","Gloeckner","GG","I love Travelling","Stockholm", "Munich to Berlin to Koeln","Bern to Zurich","blabla", "emailAThotmail.com");
  }
  textChanged(){
	  //TODO
  }
}
