import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SearchService, AuthenticationService, BuddiesService } from '../_services';
import { importType } from '@angular/compiler/src/output/output_ast';
import { ReactiveFormsModule, FormControl, FormsModule } from '@angular/forms';
import { User } from '../_models';
import { Place } from '../_models';
import { Search } from '../_models';
import { Observable } from 'rxjs';
import { EMPTY } from 'rxjs'

import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/do';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

	//Variables
	public loading: boolean;
	public offset: number;
	private searchField: FormControl;
  	public searchResults: Search[];
  	public type;
  	public query;
  	public currentUser: User;
  
  constructor(
    private serviceSearch: SearchService,
    private serviceAuthentication: AuthenticationService,
    private buddyService: BuddiesService) { 
	  
	  this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      
    
  }

	ngOnInit() {
		this.loading = false;
		this.offset = 0;
		
	    document.getElementById("home").classList.remove("home-active");
	    document.getElementById("home").classList.add("home");
	
	    document.getElementById("search").classList.remove("search");
	    document.getElementById("search").classList.add("search-active");
	
	    document.getElementById("explore").classList.remove("fas");
	    document.getElementById("explore").classList.add("far");
	
	    document.getElementById("feed").classList.remove("feed-active");
	    document.getElementById("feed").classList.add("feed");
	
	    document.getElementById("statistics").classList.remove("statistics-active");
	    document.getElementById("statistics").classList.add("statistics");
	
	    this.type = "";
	    this.query = "";
	    this.searchResults = [];
	    this.loadResults();
	}
	
	private loadResults() {
		this.loading = true;
		
		if (this.type == null) {
			this.type = "";
		}
		if (this.query == null) {
			this.query = "";
		}
		
		this.serviceSearch.searchResult(this.query, this.type, this.offset)
		.subscribe(res => {
			if(res == null) {
				return;
			}
			for (let entry of res) {
				if (entry.type == "user") {
					var user = new User(entry.id, entry.user_name, "", "", "");
					var added = true;
					if (entry.avatar == "" || entry.avatar == null) {
						user.avatar = "/assets/avatar.png";
					} else {
						user.avatar = entry.avatar;
					}
					if (entry.added == "NO") {
						added = false;
					}
					
					this.searchResults.push(new Search(entry.id, user, added, null, null, "user", null, null));
				}
				
				if (entry.type == "place") {
					var place = new Place(entry.id, entry.place_name);
					var picture = "";
					if (entry.picture == "" || entry.picture == null) {
						picture = "/assets/location.png";
					} else {
						picture = picture;
					}
					
					this.searchResults.push(new Search(entry.id, null, null, place, null, "place", entry.number, picture));
				}
				
				if (entry.type == "tag") {
					if (entry.picture == "" || entry.picture == null) {
						picture = "https://www.shareicon.net/data/64x64/2017/05/06/885605_post_512x512.png";
					} else {
						picture = picture;
					}
					
					this.searchResults.push(new Search(entry.id, null, null, null, entry.tag, "tag", entry.number, picture));
				}
			}
			this.loading = false;
		});
	}
	
	cancelSearch() {
		$("#search-query").val("");
		$(".cancel-column").hide();
		
		this.query = null;
		this.loadResults();
	}
	
	changeType(type: string) {
		this.searchResults = [];
		this.offset = 0;
		
		$(".search-type").removeClass('selected');
		$("#search-" + type).addClass('selected');
		
		if (type == 'top') {
			type = null;
		} 
		this.type = type;
		this.loadResults();
		
		console.log("type: " + this.type);
		console.log("query: " + this.query);
	}
	
	addBuddy(id: number) {
		console.log("id: " + id);
		
		this.buddyService.addBuddy(id)
		.subscribe();
		
		$("#add-" + id).removeClass("button-add");
		$("#add-" + id).addClass("button-added");
		$("#add-" + id).html("ADDED");
		
	}
	
	handle(e : KeyboardEvent) {
  		var searchValue = new String($("#search-query").val());
  		
  		if (searchValue.length >= 2) {
  			$("#search-places-button").removeClass("button-added");
  			$("#search-places-button").addClass("button-add");
  		} else {
  			$("#search-places-button").removeClass("button-add");
  			$("#search-places-button").addClass("button-added");
  		}
  		
        if(e.keyCode === 13){
        	this.onSearchChange();
        }
    }
  
	onSearchChange() {  
		this.query = $("#search-query").val();
		if (this.query != null && this.query != '' && this.query.length >= 3) {
			$(".cancel-column").show();
			this.searchResults = [];
			this.loadResults();
		} else {
			$(".cancel-column").hide();
		}
	}
	
	loadMoreResult() {
		this.offset += 10;
		this.loadResults();
	}
}
