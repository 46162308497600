import { Injectable, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';

import { catchError, map, retry } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { User } from '../_models';
import 'rxjs/Rx';
import { Place } from '../_models/place';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NftService {

  //Variables
  private authKey: string;

  //Server URL Variable
  private serverGlobal: string = environment.apiUrl;

  constructor(private httpClient: HttpClient,
              private serviceAuthentication: AuthenticationService) {
                //Set authKey
                this.authKey =  this.serviceAuthentication.getToken();
  }

  getUserNft(userNft: number) {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.httpClient.get<any>(`${environment.apiUrl}/api/nft/` + userNft, {headers: headers});
	}

  	deleteBid(bidId: number) {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.httpClient.post<any>(`${environment.apiUrl}/api/delete-bid`, {bidId: bidId}, {headers: headers});
	}
  	
  	addBid(tokens: number, badgeId: number) {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.httpClient.post<any>(`${environment.apiUrl}/api/add-bid`, {tokens: tokens, badgeId: badgeId}, {headers: headers});
	}
}