import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';

import { AlertService, UserService } from '../_services';

@Component({
    templateUrl: 'reset.component.html',
    styleUrls: ['reset.component.css']
})
export class ResetComponent implements OnInit {
    resetForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
		private location: Location,
        private formBuilder: FormBuilder,
        private router: Router,
        private userService: UserService,
        private alertService: AlertService) { }

    ngOnInit() {
        this.resetForm = this.formBuilder.group({
        	emailAddress: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.resetForm.controls; }

    backClicked() {
		this.location.back();
  	}
    
    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.resetForm.invalid) {
            return;
        }

        this.loading = true;
        
        this.userService.sendResetRequest(this.resetForm.value.emailAddress)
		.subscribe(res => {
			console.log(res);
		},
		err => {
			this.loading = false;
			$("#sendResetLinkDiv").html("please, check your email inbox and follow the instructions");
		},
	    () => {
			this.loading = false;
			$("#sendResetLinkDiv").html("please, check your email inbox and follow the instructions");
	    });
       	
    }
}
