import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '.';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddcityService {
  private authKey: string;
  private serverGlobal: string;

  constructor(private httpClient: HttpClient,
    private serviceAuthentication: AuthenticationService) {
    this.authKey =  this.serviceAuthentication.getToken();
    this.serverGlobal = environment.apiUrl;
  }

  //Post ToDo
postAddCity(newToDo):boolean
{
  const data = newToDo;
  //authorization token
  let _authKey = this.authKey;
  //setting Header
  let headers = new HttpHeaders();
  headers = headers.append('Content-Type', 'application/json');
  headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
  headers = headers.append('Authorization', `Bearer ${_authKey}`);
  
  //Post
  //todo check rest-function for availability first
  this.httpClient.post(`${this.serverGlobal}:80/api/v1/visits/3/addcity`, data, {headers: headers})
                  .subscribe((data:any) => {
                    console.log(data);
                  });

   //todo validate response and give proper return-value (success == true)
   return true;
   
}
}
