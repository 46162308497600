import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';
import { AppComponent }  from './app.component';
import { routing }        from './app.routing';
import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertService, AuthenticationService, UserService, PlacesService, PicturesService } from './_services';
import { HomeComponent } from './home';

//GoogleMaps Module
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { CommonModule } from '@angular/common';

//login + Register + Reset
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ResetComponent } from './reset';

//splash
import { SplashComponent } from './splash';
import { UnderConstructionComponent } from './underconstruction';

import { StoreComponent } from './store';
import { MissionComponent } from './mission';
import { NftComponent } from './nft';

//city
import { CityphotosComponent } from './cityphotos/cityphotos.component';
import { CitydetailComponent } from './citydetail/citydetail.component';
import { MarkersComponent } from './markers/markers.component';
import { ExploreComponent } from './explore/explore.component';
import { CitydetailplannedComponent } from './citydetailplanned/citydetailplanned.component';
import { CitypopupvisitedComponent } from './citypopupvisited/citypopupvisited.component';
import { CitypopupplannedComponent } from './citypopupplanned/citypopupplanned.component';
import { PersonprofileComponent } from './personprofile/personprofile.component';
import { CitydetailcopyComponent } from './citydetailcopy/citydetailcopy.component';
import { SearchresultComponent } from './addcity/searchresult/searchresult.component';

//nav + notification
import { NavigationComponent } from './navigation/navigation.component';
import { NotificationComponent } from './notification/notification.component'

//Search
import { SearchplacesComponent } from './searchplaces/searchplaces.component';
import { SearchtagsComponent } from './searchtags/searchtags.component';
import { SearchComponent } from './search/search.component';
import { SearchvistedbyComponent } from './searchvistedby/searchvistedby.component';
import { SearchtagsresultsComponent } from './searchtagsresults/searchtagsresults.component';
import { SearchpersonresultComponent} from './searchpersonresult/searchpersonresult.component';
import { SearchplaceresultComponent } from './searchplaceresult/searchplaceresult.component';

//feed
import { FeedComponent } from './feed/feed.component';
import { FeedService } from './_services/feed.service';

//statistics
import { StatisticsComponent } from './statistics/statistics.component';
import { StatisticscomparepopupComponent } from './statisticscomparepopup/statisticscomparepopup.component';
import { StatisticscompareComponent } from './statisticscompare/statisticscompare.component';;

//add city
import { AddcityComponent } from './addcity/addcity.component';
import { AddtagsComponent } from './addcity/addtags/addtags.component' ;
import { GiveratingComponent } from './addcity/giverating/giverating.component';
import { AddphotosComponent } from './addcity/addphotos/addphotos.component';
import { AddthingstodoComponent } from './addcity/addthingstodo/addthingstodo.component';
import { AddthingsnottodoComponent } from './addcity/addthingsnottodo/addthingsnottodo.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ProfileiconComponent } from './profileicon/profileicon.component';
import { BuyvanComponent } from './buyvan/buyvan.component';
import { WalletComponent } from './wallet/wallet.component';
import { UpdatebioComponent } from './updatebio/updatebio.component';
import { UpdatenotificationComponent } from './updatenotification/updatenotification.component';
import { BuddiesService } from './_services/buddies.service';
import { SearchPersonComponent } from './searchperson/searchperson.component';

//tag input control
import { TagInputModule } from 'ngx-chips';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EditprofileComponent } from './configuration/editprofile/editprofile.component';
import { EditvisitedComponent } from './configuration/editprofile/editvisited/editvisited.component';
import { EditplanningComponent } from './configuration/editprofile/editplanning/editplanning.component';
import { AddcityformComponent } from './addcityform/addcityform.component'; // this is needed!
import { AddcityService } from './_services/addcity.service';


import { GoogleChartsModule } from 'angular-google-charts';
import { PiechartComponent } from './statistics/piechart/piechart.component';
import { FeedableComponent } from './feed/feedable/feedable.component';


//import { MatFormFieldModule, MatInputModule,MatRadioModule } from '@angular/material';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        routing,
        GoogleChartsModule,
        FormsModule,
        AgmCoreModule.forRoot({
          apiKey: 'AIzaSyC6vz1zTaq_ebnkzjNNVvVk_nCibqgJgmE',
          libraries: ['places']
        }),
        AgmSnazzyInfoWindowModule,
        TagInputModule,
        // MatRadioModule,
        // MatFormFieldModule,
        // MatInputModule,
        BrowserAnimationsModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        SplashComponent,
        UnderConstructionComponent,
        NftComponent,
        StoreComponent,
        MissionComponent,
        ResetComponent,
        CityphotosComponent ,
        CitydetailComponent ,
        MarkersComponent ,
        ExploreComponent ,
        CitydetailplannedComponent ,
        CitypopupvisitedComponent ,
        CitypopupplannedComponent,
        PersonprofileComponent,
        CitydetailcopyComponent,
        SearchresultComponent,
        NavigationComponent,
        NotificationComponent,
        SearchplacesComponent,
        SearchtagsComponent,
        SearchComponent ,
        SearchvistedbyComponent ,
        SearchtagsresultsComponent,
        SearchpersonresultComponent ,
        SearchplaceresultComponent ,
        FeedComponent,
        StatisticsComponent,
        StatisticscomparepopupComponent,
        StatisticscompareComponent,
        AddcityComponent,
        AddtagsComponent,
        GiveratingComponent,
        AddphotosComponent,
        AddthingstodoComponent,
        AddthingsnottodoComponent ,
        ConfigurationComponent,
        BuyvanComponent,
        ProfileiconComponent,
        WalletComponent,
        UpdatebioComponent,
        UpdatenotificationComponent,
        SearchPersonComponent,
        EditprofileComponent,
        EditvisitedComponent,
        EditplanningComponent,
        AddcityformComponent,
        PiechartComponent,
        FeedableComponent
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        UserService,
        PlacesService,
        BuddiesService,
        PicturesService,
        ReactiveFormsModule,
        FeedService,
        FormsModule,
        AddcityService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }