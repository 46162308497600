import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';
import { User } from '../_models';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class UserService {
	//Server URL Variable
	private serverGlobal: string = environment.apiUrl;
	
	//Variables
	private authKey: string;

	constructor(private http: HttpClient,
            private serviceAuthentication: AuthenticationService) { 
		this.authKey =  this.serviceAuthentication.getToken();
	}

    register(name: string, email: string, password: string, password_confirmation: string) {
    	const data = {
	        "name": name,
	        "email": email,
	        "password": password,
	        "password_confirmation": password_confirmation
	    };
        //Setting Header
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        
        return this.http.post<any>(`${environment.apiUrl}/api/register`, data, {headers: headers});
    }

    update(user: User) {
        return this.http.put(`${environment.apiUrl}/users/` + user.id, user);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/users/` + id);
    }
    
	notificationNumber() {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.get<any>(`${environment.apiUrl}/api/notifications-count`, {headers: headers});
	}
	
	notifications() {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.get<any>(`${environment.apiUrl}/api/notifications`, {headers: headers});
	}
	
	acceptBuddy(idRelation: number){
		let _authKey = this.authKey;
		//Setting Header
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
		headers = headers.append("Authorization", `Bearer ` + _authKey);
		var request = this.http.post<any>(`${environment.apiUrl}/api/buddy/`+ idRelation +`/accept`, null, {headers: headers});
	    return request;
	} 
	
	uploadUserAvatar(imageSrc) {
		let formData: FormData = new FormData();
		let _authKey = this.authKey;
		//Setting Header
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('mimeType', 'multipart/form-data');
		headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
		headers = headers.append("Authorization", `Bearer ` + _authKey);

		imageSrc = imageSrc.toString().replace("data:image/png;base64,","").replace("data:image/jpg;base64,","").replace("data:image/jpeg;base64,","");
		
		var request = this.http.post<any>(`${environment.apiUrl}/api/upload-avatar`, { image: imageSrc }, {headers: headers});
		return request;
	}
	
	getWallet() {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.get<any>(`${environment.apiUrl}/api/wallet`, {headers: headers});
	}
	
	getUserReferred() {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.get<any>(`${environment.apiUrl}/api/user-referred`, {headers: headers});
	}
	
	
	
	/**
	 * check if the user used the missions, it returns the not avaiable missions
	 */
	checkMissions(type: string) {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.post<any>(`${environment.apiUrl}/api/check-mission`, {type: type}, {headers: headers});
	}
	
	claimMission(type: string) {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.post<any>(`${environment.apiUrl}/api/claim-mission`, {type: type}, {headers: headers});
	}
	
	getWalletHistory() {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.get<any>(`${environment.apiUrl}/api/get-wallet-history`, {headers: headers});
	}
	
	getBadges() {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.get<any>(`${environment.apiUrl}/api/user-badges`, {headers: headers});
	}
	
	getUserMissions() {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.get<any>(`${environment.apiUrl}/api/get-user-missions`, {headers: headers});
	}
	
	getAllBids() {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.get<any>(`${environment.apiUrl}/api/get-all-bids`, {headers: headers});
	}
	
	getAllGroupedBids() {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.get<any>(`${environment.apiUrl}/api/get-all-grouped-bids`, {headers: headers});
	}
	
	getAllMissions() {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.get<any>(`${environment.apiUrl}/api/get-all-missions`, {headers: headers});
	}
	
	
	
	referAfriend(code: string) {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.post<any>(`${environment.apiUrl}/api/insert-referral`, {code: code}, {headers: headers});
	}
	
	pay(userMissionIdToPay: number) {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.post<any>(`${environment.apiUrl}/api/pay-mission`, {userMissionId: userMissionIdToPay}, {headers: headers});
	}
	
	deleteAccount(password: string) {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.post<any>(`${environment.apiUrl}/api/delete-account`, {password: password}, {headers: headers});
	}
	
	updateProfileId(profileId: string) {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.post<any>(`${environment.apiUrl}/api/profile-id`, {profileId: profileId}, {headers: headers});
	}
	
	payBid(bidIdToPay: number) {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.post<any>(`${environment.apiUrl}/api/pay-bid`, {bidId: bidIdToPay}, {headers: headers});
	}
	
	
	declineBuddy(idRelation: number){
		let _authKey = this.authKey;
		//Setting Header
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
		headers = headers.append("Authorization", `Bearer ` + _authKey);
		
		var request = this.http.post<any>(`${environment.apiUrl}/api/buddy/`+ idRelation +`/decline`, null, {headers: headers});
	    return request;
	} 
	
	sendResetRequest(emailAddress: string) {
		let _authKey = this.authKey;
		//Setting Header
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
		headers = headers.append("Authorization", `Bearer ` + _authKey);
		
		return this.http.post<any>(`${environment.apiUrl}/api/forget`, { email: emailAddress }, {headers: headers});
		
//		return Observable.of(request);
	} 
	
	updateBio(bio: string){
		let _authKey = this.authKey;
		//Setting Header
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
		headers = headers.append("Authorization", `Bearer ` + _authKey);
		var request = this.http.post<any>(`${environment.apiUrl}/api/update-bio`, { bio: bio }, {headers: headers});
	    return request;
	} 
	
	updateNotification(type: string, value: boolean){
		let _authKey = this.authKey;
		//Setting Header
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
		headers = headers.append("Authorization", `Bearer ` + _authKey);
		var request = this.http.post<any>(`${environment.apiUrl}/api/update-notification`, { type: type, value: value}, {headers: headers});
	    return request;
	} 
	
	getNotificationSettings() {
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);

	    return this.http.get<any>(`${environment.apiUrl}/api/get-notification-settings`, {headers: headers});
	}
	
}