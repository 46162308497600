import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { Place } from '../_models';
import { UserService } from '../_services';
import { PlacesService, AuthenticationService } from '../_services';

import { ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

@Component({
  selector: 'app-markers',
  templateUrl: './markers.component.html',
  styleUrls: ['./markers.component.css']
})
export class MarkersComponent implements OnInit {
	public markers = [];
	public offset: number;
	
	public location_id: number;
	public user_id: number;
	
	public loading: boolean;
	
	constructor(private userService: UserService,
			private servicePlace: PlacesService,
			private serviceAuthentication: AuthenticationService,
		    private route: ActivatedRoute) {
	}

 
	ngOnInit() {
		this.offset = 0;
		this.markers = [];
		this.route.params.subscribe(
			params => {
				let location_id = +params['location_id'];
				this.location_id = location_id;
				this.searchPlacesByLocation();
				$(".custom-title").html('Markers');
		});
	}
	
	searchPlacesByLocation() {
		this.loading = true;
		this.servicePlace.searchPlacesByLocation(this.location_id, this.offset)
		.subscribe(
		(data: any []) => {
			for (let marker of data) {
				if (marker.user_avatar == null || marker.user_avatar == "") {
					marker.user_avatar = '/assets/avatar.png';
				}
				
				if (marker.chosendate != null && marker.chosendate != "") {
//					var d = new Date(marker.chosendate);
					var datestring = marker.chosendate.substring(0, 10);
					marker.chosendate = datestring;
				}
				this.markers.push(marker);
		    }
			
			this.loading = false;
			console.log("*****************");
			console.log(this.markers);
			console.log("*****************");
		});
	}
	
	
	loadMoreResult() {
		this.offset += 10;
		if (this.location_id != null) {
			this.searchPlacesByLocation();
		}
	}

}
