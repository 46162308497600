import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, EMPTY, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PlacesService {

  private loading: boolean;
  private places: any /* Places */;
  //Variables
  private authKey: string;

    constructor(private httpClient: HttpClient,
      private serviceAuthentication: AuthenticationService) {
        //Set authKey
        this.authKey =  this.serviceAuthentication.getToken();
        this.places = [];
        this.loading = false;
   }

  //Server URL Variable
  private serverGlobal: string = environment.apiUrl;
   
	feed(offset: number) {
		//Set authKey
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    
	    if (offset == null) {
	    	offset = 0;
	    }
	    
	    //Get Request
	    var request = this.httpClient.get<any>(`${environment.apiUrl}/api/feed?offset=${offset}`, {headers: headers});
		    return request;
	}
	
	
	markNotification(id: number){
		let _authKey = this.authKey;
		//Setting Header
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
		headers = headers.append("Authorization", `Bearer ` + _authKey);
		
		var request = this.httpClient.post<any>(`${environment.apiUrl}/api/mark-notification`, {notificationId: id}, {headers: headers});
	    return request;
	} 
	
	explore(offset: number) {
		//Set authKey
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    
	    if (offset == null) {
	    	offset = 0;
	    }
	    
	    //Get Request
	    var request = this.httpClient.get<any>(`${environment.apiUrl}/api/explore?offset=${offset}&type=location`, {headers: headers});
	    return request;
   }
	
	
	searchPlacesByLocation(locationId: number, offset: number) {
		//Set authKey
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    
	    if (offset == null) {
	    	offset = 0;
	    }
	    
	    //Get Request
	    var request = this.httpClient.get<any>(`${environment.apiUrl}/api/search-markers?id=${locationId}&offset=${offset}&type=location`, {headers: headers});
	    return request;
   }
	
	searchPlacesByUserId(userId: number, offset: number) {
		//Set authKey
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    
	    if (offset == null) {
	    	offset = 0;
	    }
	    
	    //Get Request
	    var request = this.httpClient.get<any>(`${environment.apiUrl}/api/search-markers?id=${userId}&offset=${offset}&type=user`, {headers: headers});
	    return request;
   }
	
	getPlace(id: number) {
		//Set authKey
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    //Get Request
	    var request = this.httpClient.get<any>(`${environment.apiUrl}/api/marker/` +id, {headers: headers});
	    return request;
   }
	
	getPlaces() {
		//Set authKey
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    //Get Request
	    var request = this.httpClient.get<any>(`${environment.apiUrl}/api/places`, {headers: headers});
	    return request;
	    
	}
	
	uploadMarkerImages(imageSrc, markerId: number, isMainImage: boolean, name) {
		let formData: FormData = new FormData();
		let _authKey = this.authKey;
		//Setting Header
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('mimeType', 'multipart/form-data');
		headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
		headers = headers.append("Authorization", `Bearer ` + _authKey);
	
		imageSrc = imageSrc.toString().replace("data:image/png;base64,","").replace("data:image/jpg;base64,","").replace("data:image/jpeg;base64,","");
		
		var request = this.httpClient.post<any>(`${environment.apiUrl}/api/upload-marker-images`, { image: imageSrc, markerId : markerId, isMainImage : isMainImage, name : name}, {headers: headers});
		return request;
	}
	
	getMarkerImages(markerId: number) {
		//Set authKey
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    //Get Request
	    var request = this.httpClient.get<any>(`${environment.apiUrl}/api/marker-images/` +markerId, {headers: headers});
	    return request;
   }
	
	// remove a marker
	removeMarker(markerId: number){
		let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
//	    var t = this.httpClient.get<any>(`${environment.apiUrl}/api/places`, {headers: headers});
	    var t = this.httpClient.post<any>(`${environment.apiUrl}/api/delete-marker`, { id: markerId }, {headers: headers});
	    return t;
	} 
	
	// add / remove like
	like(markerId: number){
		let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    var t = this.httpClient.post<any>(`${environment.apiUrl}/api/insert-like`, { markerId: markerId }, {headers: headers});
	    return t;
	} 
	
	// add gift
	gift(markerId: number){
		let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    var t = this.httpClient.post<any>(`${environment.apiUrl}/api/insert-gift`, { markerId: markerId }, {headers: headers});
	    return t;
	} 
	
	// 
	deleteMarkerImages(markerId: number){
		let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
//	    var t = this.httpClient.get<any>(`${environment.apiUrl}/api/places`, {headers: headers});
	    var t = this.httpClient.post<any>(`${environment.apiUrl}/api/delete-marker-images`, { markerId: markerId }, {headers: headers});
	    return t;
	} 
	
	  //getPlaces of a specific user
	getPlacesByUserId(id: number) {
		//Set authKey
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    //Get Request
	    var request = this.httpClient.get<any>(`${environment.apiUrl}/api/places-by-user/` +id, {headers: headers});
	    return request;
	    
	} // GetPlaces function

  //Handling Errors
  getErrorHandler(error: HttpErrorResponse){

  }

  //Post Place
  postPlace(newPlace)
  {
    const data = newPlace;
    //authorization token
    let _authKey = this.authKey;
    //setting Header
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', 'http://localhost:4200/');
    headers = headers.append('Authorization', `Bearer ${_authKey}`);
    //Post
    this.httpClient.post(`${this.serverGlobal}:80/api/v1/place`, data, {headers: headers})
                    .subscribe((data:any) => {
                      console.log(data);
                    });
  }//Post Place function

  //Delete Place
  deletePlace(){
    //Set Place
    const data: Place = {
      name: "Bern",
      google_city_id: 'ChIJGaN-SZcLkEcRA9wf5_GNbuY',
      location: { 
        lat: 46.376887,
        long: 9.541694
      }
    }

    //authorization token
    let _authKey = this.authKey;
    //setting Header
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', 'http://localhost:4200/');
    headers.append('Authorization', `Bearer ${_authKey}`);
    //delete Place
    //this.httpClient.delete(`${this.serverGlobal}:8000/api/v1/${data.name}`, data, {headers})
                   // .subscribe((data:any) => {
                   //   console.log(data);
                   // });
  }
  
  
  getMarkersWithTheSameLocation(lat: number, long: number) {
		//Set authKey
		this.authKey =  this.serviceAuthentication.getToken();
	    let _authKey = this.authKey;
	    //Setting Header
	    let headers = new HttpHeaders();
	    headers = headers.append('Content-Type', 'application/json');
	    headers = headers.append('Access-Control-Allow-Origin', this.serverGlobal);
	    headers = headers.append("Authorization", `Bearer ` + _authKey);
	    
	    var request = this.httpClient.post<any>(`${environment.apiUrl}/api/check-location`, {lat: lat, long: long}, {headers: headers});
	    return request;
 }
  
}//Places Service

//Marker interface
interface Place{
  name: string,
  location: { 
    lat: number,
    long: number
  },
  google_city_id: string
  /*name_short: string,
  country_long: string,
  country_short: string,
  elevation: number,
  visited: boolean,
  continent: string */
}
