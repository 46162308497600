import { Component, OnInit } from '@angular/core';
import { TagInputModule } from 'ngx-chips';
import { Observable } from 'rxjs';
import { Place } from '../_models/place';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

@Component({
  selector: 'app-searchtags',
  templateUrl: './searchtags.component.html',
  styleUrls: ['./searchtags.component.css']
})
export class SearchtagsComponent implements OnInit {
  public chosenTagList:string[];
  public tagNames: string[];
  private filteredPlaces: Place[];
  private router:Router;
  
  constructor(_router: Router){
    this.router = _router;
  }

  ngOnInit() {
    this.chosenTagList = [];
    this.tagNames = [
    'Boats', 
    'Beach', 
    'Culture',   
    'Camping',   
    'Hiking',   
    'Honeymoon', 
    'Lowbudget',   
    'Nature',   
    'Nightlife',   
    'Surfing' 
    ];
    this.filteredPlaces = [new Place(1,"Barcelona"), new Place(2,"Geneva")];
    
  }
  // private addPlace(tag:string){
  //   this.filteredPlaces.push(new Place(1, tag));
  // }

  // private removePlace(tag:string){

  // }

  public onAdding(tag: string){
    //todo call backEnd
    //filteredPlaces = backendService.filterPlacesViaTags(chosenTagList);
    this.filteredPlaces = [new Place(1,"Barcelona"), new Place(2,"Geneva")];
   
  }

  public onRemoving(tag:string){
    //todo call backEnd
    //filteredPlaces = backendService.filterPlacesViaTags(chosenTagList);
    this.filteredPlaces = [new Place(1,"Barcelona"), new Place(2,"Geneva")];
  }
  public showResults(){
    this.router.navigate(['/searchtagsresults',JSON.stringify(this.filteredPlaces)]);
  }

  public buttonPressed(tagName: string){
    console.log(tagName);
      let isContained = false;
      this.chosenTagList.forEach(tag => {
        if(tag == tagName)
          {
            isContained = true;
          }
      });
      if(!isContained)
        this.chosenTagList.push(tagName);
  }
}
