import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statisticscomparepopup',
  templateUrl: './statisticscomparepopup.component.html',
  styleUrls: ['./statisticscomparepopup.component.css']
})
export class StatisticscomparepopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
