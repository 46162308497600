import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { UserService } from '../_services';
import { AuthenticationService } from '../_services';

import { ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

@Component({
  selector: 'app-updatebio',
  templateUrl: './updatebio.component.html',
  styleUrls: ['./updatebio.component.css']
})
export class UpdatebioComponent implements OnInit {
	public currentUser: User;
	public loading: boolean = false;
	public file: File;
	public showSaveAvatar: boolean = false;

	constructor(private userService: UserService,
			private router: Router,
			private serviceAuthentication: AuthenticationService) {
		
	}


	ngOnInit() {
		console.log(localStorage.getItem('currentUser'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        
		this.file = null;
		this.showSaveAvatar = false;
	}
	
	updateBio() {
		this.loading = true;
		$("#message").html("");
		this.userService.updateBio(this.currentUser.bio).subscribe((res: any []) => {
			var data = JSON.stringify(this.currentUser);
			localStorage.setItem('currentUser', data);
			this.router.navigate(['/']);
	    },
	    error => {
	        console.log('error on changing bio: ', error);
	        $("#message").html("error on changing bio");
	        this.loading = false;
	    });
		
	}
}
