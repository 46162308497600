import { Place } from ".";
import { Tip } from ".";
import { User } from ".";
import { Comment } from ".";
import { Photo } from ".";

export class Addcity {
	public id: number;
	public cityname: string;
	public cityNameShort: string;
	public description: string;
	public osmId: number;
	public marker: any;
	public country: string;
	public country_code: string;
    public chosenTagList:Place[];
    public selectedFile:string;
    public todo: Tip[];
    public nottodo: Tip[];
    public rating: number;
    public photos: Photo[]; 
    public chosenDate: string;
    public howlong: number;
	public planned: number;
    public isvisited: boolean;
	public user: User;
	public dateVisited: Date;
	public location: {
		lat: number
	    long: number
	}
	public comments: Comment[];

	public month: string;
	public year: number;
	public likes: number;
	public gifts: number;
		
    constructor(cityname) {
    	this.cityname = cityname;
    }
}