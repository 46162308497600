import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { UserService } from '../_services';
import { AuthenticationService } from '../_services';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {
	public currentUser: User;
	public allBidLoading: boolean;
	public allBids;
	
	public bidIdToPay;
	public tokensToPay;
	public error;

	public title;
	public fullDescription;
	public amountLeft;
	public current_state;
	public tokens;
	public image;
	public link;
	public bidId;
	
	constructor(private userService: UserService,
			private router: Router,
			private serviceAuthentication: AuthenticationService) { }

  	ngOnInit() {
	  
	  document.getElementById("home").classList.remove("home-active");
	  document.getElementById("home").classList.add("home");
	
	  document.getElementById("search").classList.remove("search-active");
	  document.getElementById("search").classList.add("search");
	
	  document.getElementById("explore").classList.remove("fas");
	  document.getElementById("explore").classList.add("far");
	
	  document.getElementById("feed").classList.remove("feed-active");
	  document.getElementById("feed").classList.add("feed");
	
	  document.getElementById("statistics").classList.remove("fal");
	  document.getElementById("statistics").classList.add("fas");
	  
	  this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
	  
	  this.loadBids();
	  
  	}
  	
  	loadBids() {
  		this.allBidLoading = true;
  		this.allBids = null;
//  		this.userService.getAllBids()
//  			.subscribe(res => {
//  				
//  			this.allBids = res;
//  			console.log(this.allBids);
//  			this.allBidLoading = false; 
//  		});
  		
  		this.userService.getAllGroupedBids()
			.subscribe(res => {
				
			this.allBids = res;
			for (let bid of this.allBids) {
				for (let bidFound of bid.badge[0].bids) {
					if (bid.tokens == bidFound.tokens && bid.current_state == bidFound.current_state) {
						bid.bid = bidFound;
					}
				}
			}
			console.log(this.allBids);
			this.allBidLoading = false; 
		});
  		
  		
  	}
  	
  	pay() {
		this.error = "";
		  
		this.userService.payBid(this.bidIdToPay).subscribe(
		    result => {
		    	console.log(result);
				if (result == -1) {
					this.error = "Bid not activated or not in the right state";
				} else if (result == -2) {
					this.error = "No enough tokens to pay this item";
				} else if (result == -4) {
					this.error = "You own already this NFT";
				} else if (result == 0) {
					$("#dialog-pay").hide();
					this.loadBids();
				} else {
					this.error = "An error occured during the process [UNKNOWN]";
				}
		     },
		    error => {
		    	console.error(error);
		    	this.error = "An error occured during the process";
		    } );
	}
	
	public openDialogPay (bidId, tokens) {
		this.error = null;
		$("#dialog-pay").show();
		$("#dialog-detail").hide();
		this.bidIdToPay = bidId;
		this.tokensToPay = tokens;
	}
	
	public openDialogDetail (title, fullDescription, link, amountLeft, image, current_state, tokens, bidId) {
		this.title = title;
		this.image = image;
		this.fullDescription = fullDescription;
		this.link = link;
		this.amountLeft = amountLeft;
		this.current_state = current_state;
		this.tokens = tokens;
		this.bidId = bidId;
		
		$("#dialog-detail").show();
	}
	
	public closeDetailDialog() {
  		$("#dialog-detail").hide();
	}
	
	public closePayDialog() {
  		$("#dialog-pay").hide();
	}

}
