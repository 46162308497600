import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.css']
})
export class PiechartComponent implements OnInit {
 @Input() currentVal: number;
  
  public dataChart: any;
   public title:string;
   public chartType:string;
   public chartData:Array<any> = [];
   public options:any;
   public tempVal:number;

  constructor() { }

  ngOnInit() {
    console.log("currentVAL: "+this.currentVal);
    this.tempVal =parseInt(""+this.currentVal) || 60;
    this.title = "Visited Country";
    this.chartType="PieChart";
    this.chartData = [
      ['', this.tempVal],
      ['', 134]
    ];

    this.options = {
      title: 'My Daily Activities',
      pieSliceText: 'none',
      tooltip: { trigger: 'none' },
      legend: {position: 'none'},
      titlePosition: 'none',
      colors:['#e5e7ea','#f44248'],
      pieHole: 0.85
    };
  }

}
