import { Component, OnInit } from '@angular/core';
import { User } from '../_models/user';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService, AuthenticationService } from '../_services';
@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {
	public currentUser: User;
	public password;
	public error;
	public profileId;

	constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private authenticationService: AuthenticationService) {
		

	}

  ngOnInit() {
    this.loadUser();
  }
  
  private loadUser(){
	  this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }
  
	logout() {
		// reset login status
		this.authenticationService.logout();
		this.router.navigate([this.route.snapshot.queryParams['returnUrl'] || '/']);
	}
	
	public openDialogDeleteAccount() {
		this.error = null;
		$("#dialog-deleteaccount").show();
	}
	
	public closeDeleteAccount() {
  		$("#dialog-deleteaccount").hide();
	}
	
	public manageProfile() {
		if ($(".profile-menu").is(':visible')) {
			$(".profile-menu").hide();
		} else {
			$(".profile-menu").show();
		}
	}
	
	public manageKeepInTouch() {
		if ($(".keepintouch-menu").is(':visible')) {
			$(".keepintouch-menu").hide();
		} else {
			$(".keepintouch-menu").show();
		}
	}
	
	public deleteAccount() {
		this.error = "";
		
		if (this.password == null || this.password == "") {
			this.error = "Please insert your password";
			return;
		}

		this.userService.deleteAccount(this.password).subscribe(
		    result => {
		    	console.log("TEST");
		    	console.log(result);
				if(result == -1) {
					this.error = "Password doesn't match";
				} else {
					$(".dialog-deleteaccount").hide();
				}
		     },
		    error => {
		    	console.error(error);
		    	this.error = "An error occured during the process";
		    } );
	}
}
