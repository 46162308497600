﻿import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { ResetComponent } from './reset';
import { SplashComponent } from './splash';
import { UnderConstructionComponent } from './underconstruction';
import { NftComponent } from './nft';
import { StoreComponent } from './store';
import { MissionComponent } from './mission';
import { AuthGuard } from './_guards';

import { CityphotosComponent } from './cityphotos/cityphotos.component';
import { CitydetailComponent } from './citydetail/citydetail.component';
import { MarkersComponent } from './markers/markers.component';
import { ExploreComponent } from './explore/explore.component';
import { CitydetailplannedComponent } from './citydetailplanned/citydetailplanned.component';
import { CitypopupvisitedComponent } from './citypopupvisited/citypopupvisited.component';
import { CitypopupplannedComponent } from './citypopupplanned/citypopupplanned.component';
import { PersonprofileComponent } from './personprofile/personprofile.component';
import { CitydetailcopyComponent } from './citydetailcopy/citydetailcopy.component';

import { NavigationComponent } from './navigation/navigation.component';
import { NotificationComponent } from './notification/notification.component';

import { SearchplacesComponent } from './searchplaces/searchplaces.component';
import { SearchtagsComponent } from './searchtags/searchtags.component';
import { SearchComponent } from './search/search.component';
import { SearchvistedbyComponent } from './searchvistedby/searchvistedby.component';
import { SearchtagsresultsComponent } from './searchtagsresults/searchtagsresults.component';
import { SearchpersonresultComponent } from './searchpersonresult/searchpersonresult.component';
import { SearchplaceresultComponent } from './searchplaceresult/searchplaceresult.component';
import { SearchPersonComponent } from './searchperson/searchperson.component';
import { FeedComponent } from './feed/feed.component';

import { StatisticsComponent } from './statistics/statistics.component';
import { StatisticscomparepopupComponent } from './statisticscomparepopup/statisticscomparepopup.component';
import { StatisticscompareComponent } from './statisticscompare/statisticscompare.component';
 
//add city
import {AddcityformComponent} from './addcityform/addcityform.component';
import { AddcityComponent } from './addcity/addcity.component';
import { AddtagsComponent } from './addcity/addtags/addtags.component' ;
import { GiveratingComponent } from './addcity/giverating/giverating.component';
import { AddphotosComponent } from './addcity/addphotos/addphotos.component';
import { AddthingstodoComponent } from './addcity/addthingstodo/addthingstodo.component';
import { AddthingsnottodoComponent } from './addcity/addthingsnottodo/addthingsnottodo.component';

import { ConfigurationComponent } from './configuration/configuration.component';
import {EditprofileComponent} from './configuration/editprofile/editprofile.component';
import {EditvisitedComponent} from './configuration/editprofile/editvisited/editvisited.component';
import {EditplanningComponent} from './configuration/editprofile/editplanning/editplanning.component';
import { ProfileiconComponent } from './profileicon/profileicon.component';
import { BuyvanComponent } from './buyvan/buyvan.component';
import { WalletComponent } from './wallet/wallet.component';
import { UpdatebioComponent } from './updatebio/updatebio.component';
import { UpdatenotificationComponent } from './updatenotification/updatenotification.component';



const appRoutes: Routes = [
    { path: '', component: ExploreComponent, canActivate: [AuthGuard] },
    { path: 'home', component: HomeComponent },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
 	{ path: 'reset', component: ResetComponent },
	 
    { path: 'splash', component: SplashComponent },
    { path: 'underconstruction', component: UnderConstructionComponent },
    { path: 'nft/:user_badge_id', component: NftComponent },
    
    { path: 'store', component: StoreComponent },
    { path: 'mission', component: MissionComponent },
    { path: 'citydetailplanned', component: CitydetailplannedComponent},
    { path: 'citydetail/:marker_id', component: CitydetailComponent },
    { path: 'markers/location/:location_id', component: MarkersComponent },
    { path: 'explore', component: ExploreComponent },
    { path: 'cityphotos', component: CityphotosComponent },
    { path: 'citypopupplanned', component: CitypopupplannedComponent },
    { path: 'citypopupvisited', component: CitypopupvisitedComponent },
    { path: 'personprofile/:id', component: PersonprofileComponent},
    { path: 'citydetailcopy', component: CitydetailcopyComponent},

    { path: 'navigation', component: NavigationComponent},
    { path: 'notification', component: NotificationComponent},

    { path: 'search', component: SearchComponent},
    { path: 'searchplaces', component: SearchplacesComponent},
    { path: 'searchtags', component: SearchtagsComponent},
    { path: 'searchvisitedby', component: SearchvistedbyComponent},
    { path: 'searchtagsresults/:filteredplaces', component: SearchtagsresultsComponent},
    { path: 'searchpersonresult/:id', component: SearchpersonresultComponent},
    { path: 'searchplaceresult/:id', component: SearchplaceresultComponent},
    { path: 'searchperson', component: SearchPersonComponent},
    

    { path: 'feed', component: FeedComponent},

    { path: 'statistics', component: StatisticsComponent},
    { path: 'statisticscomparepopup', component: StatisticscomparepopupComponent},
    { path: 'statisticscompare', component: StatisticscompareComponent},

    //addcity   
    { path: 'addcity', component: AddcityformComponent,
        children:[
            { path: '', component: AddcityComponent},
            { path: 'tags', component: AddtagsComponent},
            { path: 'rating', component: GiveratingComponent},
            { path: 'photos', component: AddphotosComponent},
            { path: 'thingstodo', component: AddthingstodoComponent},
            { path: 'thingsnottodo', component: AddthingsnottodoComponent}  
        ]
    },
    //editcity   
//    { path: 'addcity/:id', component: AddcityformComponent,
//        children:[
//            { path: '', component: AddcityComponent},
//            { path: 'tags', component: AddtagsComponent},
//            { path: 'rating', component: GiveratingComponent},
//            { path: 'photos', component: AddphotosComponent},
//            { path: 'thingstodo', component: AddthingstodoComponent},
//            { path: 'thingsnottodo', component: AddthingsnottodoComponent}  
//        ]
//    },

    //Notification
    { path: 'configuration', component: ConfigurationComponent},
    { path: 'editprofile', component: EditprofileComponent},
    { path: 'editvisited', component: EditvisitedComponent},
    { path: 'editplanning', component: EditplanningComponent},


    { path: 'profileicon', component: ProfileiconComponent},
    { path: 'buyvan', component: BuyvanComponent},
    { path: 'wallet', component: WalletComponent},
    { path: 'updatebio', component: UpdatebioComponent},
    { path: 'updatenotification', component: UpdatenotificationComponent},
    
    

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routingConfiguration: ExtraOptions = {
		  paramsInheritanceStrategy: 'always'
		};

export const routing = RouterModule.forRoot(appRoutes, routingConfiguration);