import { Component, OnInit, Input } from '@angular/core';
import { Feedable } from '../../_models/feedable';

@Component({
  selector: 'app-feedable',
  templateUrl: './feedable.component.html',
  styleUrls: ['./feedable.component.css']
})
export class FeedableComponent implements OnInit {
  @Input() feedable: Feedable;
  constructor() { }

  ngOnInit() {
  }

}
