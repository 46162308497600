import { Component, OnInit } from '@angular/core';
import { TagInputModule } from 'ngx-chips';
import { Observable } from 'rxjs';
import { Place } from '../../_models/place';
import { AddcityformComponent } from '../../addcityform/addcityform.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addtags',
  templateUrl: './addtags.component.html',
  styleUrls: ['./addtags.component.css']
})
export class AddtagsComponent implements OnInit {
	public chosenTags: Place[];
	public tags: string[];

	public showSearchBar: boolean = true;
	public showSearchResults: boolean = false;
  
  constructor(
    private router: Router,
    private addcityform: AddcityformComponent
  ){
  }
  
	cancelSearch() {
		$("#search").val("");
		$(".cancel-column").hide();
		this.showSearchResults = false;
	}
	
	onSearchChange(searchValue : string ) {  
		if (searchValue != null && searchValue != '') {
			$(".cancel-column").show();
			this.showSearchResults = true;
			
		} else {
			this.showSearchResults = false;
		}
	}
	
	selectTag (tag : string ) {  
		let isContained = false;
		this.chosenTags.forEach(tagFromList => {
			if(tag == tagFromList.name) {
				isContained = true;
			}
		});
		if(!isContained) {
			this.chosenTags.push(new Place(1/*TODO LEAVE*/, tag));
			document.getElementById("search-" + tag).classList.remove("button-add");
		    document.getElementById("search-" + tag).classList.add("button-added");
		    document.getElementById("search-" + tag).innerHTML = "ADDED";
		}
  	}
	
	contained (tag : string ) {  
		let isContained = false;
		this.chosenTags.forEach(tagFromList => {
			if(tag == tagFromList.name) {
				isContained = true;
			}
		});
		return isContained;
  	}
	
	deleteTag (tag : Place ) {
		this.chosenTags.splice(this.chosenTags.indexOf(tag), 1);
		document.getElementById("search-" + tag).classList.remove("button-added");
	    document.getElementById("search-" + tag).classList.add("button-add");
	    document.getElementById("search-" + tag).innerHTML = "ADD";
	}
	
  ngOnInit() {
    this.tags = [
    'Boats', 
    'Beach', 
    'Culture',   
    'Camping',   
    'Hiking',   
    'Honeymoon', 
    'Lowbudget',   
    'Nature',   
    'Nightlife',   
    'Surfing' 
    ];
    
    this.chosenTags = this.addcityform.addcityModel.chosenTagList;
    if (this.chosenTags == null) {
	   this.chosenTags = [];
    }
    
  }

  
	public addTags(){
		console.log(this.chosenTags);
		this.addcityform.addcityModel.chosenTagList = this.chosenTags;
		this.router.navigate(['/addcity']);
	}
}