import { Component, OnInit } from '@angular/core';
import { Addcity } from '../_models';

@Component({
  selector: 'app-addcityform',
  templateUrl: './addcityform.component.html',
  styleUrls: ['./addcityform.component.css']
})
export class AddcityformComponent implements OnInit {
  public addcityModel: Addcity;
  constructor() { }

  ngOnInit() {
    this.addcityModel = new Addcity("");
  }
 
}
