import { Component, OnInit } from '@angular/core';
import { PlacesService } from '../_services';
import { GoogleChartsModule } from 'angular-google-charts';
import { Chart } from '../_models';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

	//Variable for Places
	public markers: number;

   	public countryVisited: number;
	public countryVisitedTotal: number;

	public farthestFromHome: number;
	public farthestFromHomeTotal: number;

	public highestMountain: number;
	public highestMountainTotal: number;
	
	public southernmostPoint: number;
	public southernmostPointTotal: number;


   public chartVisitedStates: Chart;
   public chartFarthestFromHome: Chart;
   public chartHighestMountain: Chart;
   public chartSouthernmostPoint: Chart;

   constructor(private servicePlace: PlacesService) { }
 
   ngOnInit() {
	   // CHARTS OPTIONS
	   const options = {
		        pieHole: 0.8,
		        legend: 'none',
		        pieSliceText: 'none',
		        slices: {
		            0: { color: '#ff4959' },
		            1: { color: '#d6d6d6' }
		          },
		        width: 160,
		        height: 160,
		        chartArea:{width:'100%',height:'100%'}
		    };
	   this.setValues();
	   
	   // FIRST CHART 
	   this.getVisitedStates(options);
	   
	   // SECOND CHART 
	   this.getFarthestFromHome(options);
    
	   // THIRD CHART 
	   this.getHighestMountain(options);
    
	   // FOURTH CHART
	   this.getSouthernmostPoint(options);


	   document.getElementById("home").classList.remove("home-active");
	   document.getElementById("home").classList.add("home");
		
	   document.getElementById("search").classList.remove("search-active");
	   document.getElementById("search").classList.add("search");
		
	   document.getElementById("explore").classList.remove("fas");
	   document.getElementById("explore").classList.add("far");
		
	   document.getElementById("feed").classList.remove("feed-active");
	   document.getElementById("feed").classList.add("feed");
		
	   document.getElementById("statistics").classList.remove("statistics");
	   document.getElementById("statistics").classList.add("statistics-active");
   	}//onInit
    
    private setValues() {
    	// TODO these values should be loaded by a web service
    	this.countryVisited = 96;
		this.countryVisitedTotal = 194;
	
		this.farthestFromHome = 12213;
		this.farthestFromHomeTotal = 228804;
	
		this.highestMountain = 10;
		this.highestMountainTotal = 8848;
		
		this.southernmostPoint = 12;
		this.southernmostPointTotal = 1207;
		
		this.markers = 5;
    }
   
	private getVisitedStates(options) {
		this.chartVisitedStates = new Chart();
		this.chartVisitedStates.chartType = "PieChart";
		this.chartVisitedStates.chartData = [
			['Visited', this.countryVisited],
			['To visit', this.countryVisitedTotal]
			];

		this.chartVisitedStates.options = options;
	}
	
	private getFarthestFromHome(options) {
		this.chartFarthestFromHome = new Chart();
	    this.chartFarthestFromHome.chartType = "PieChart";
	    this.chartFarthestFromHome.chartData = [
	    	['Actual', this.farthestFromHome],
	    	['Total', this.farthestFromHomeTotal]
	    ];
	    
	    this.chartFarthestFromHome.options = options;
	}
	
	private getHighestMountain(options) {
		this.chartHighestMountain = new Chart();
	    this.chartHighestMountain.chartType = "PieChart";
	    this.chartHighestMountain.chartData = [
	    	['Actual', this.highestMountain],
	    	['Total', this.highestMountainTotal]
	    ];
	    
	    this.chartHighestMountain.options = options;
	}
	
	private getSouthernmostPoint(options) {
		this.chartSouthernmostPoint = new Chart();
	    this.chartSouthernmostPoint.chartType = "PieChart";
	    this.chartSouthernmostPoint.chartData = [
	        ['Actual', this.southernmostPoint],
	        ['Total', this.southernmostPointTotal]
	    ];
	    
	    this.chartSouthernmostPoint.options = options;
	}
   
   
 }
 
 