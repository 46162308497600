import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { Place } from '../_models';
import { UserService } from '../_services';
import { PlacesService, AuthenticationService } from '../_services';

import { ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css']
})
export class ExploreComponent implements OnInit {
	public elements = [];
	public offset: number;
	public user_id: number;
	public loading: boolean;
	
	constructor(private userService: UserService,
			private servicePlace: PlacesService,
			private serviceAuthentication: AuthenticationService,
		    private route: ActivatedRoute) {
		
	}

 
	ngOnInit() {
		this.offset = 0;
		this.elements = [];
		
		document.getElementById("home").classList.remove("home-active");
	    document.getElementById("home").classList.add("home");
	
	    document.getElementById("search").classList.remove("search-active");
	    document.getElementById("search").classList.add("search");
	
	    document.getElementById("explore").classList.remove("far");
	    document.getElementById("explore").classList.add("fas");
	
	    document.getElementById("feed").classList.remove("feed-active");
	    document.getElementById("feed").classList.add("feed");
	
	    document.getElementById("statistics").classList.remove("statistics-active");
	    document.getElementById("statistics").classList.add("statistics");
		
		this.explore();
		$(".custom-title").html('Explore');
	}
	
	explore() {
		this.loading = true;
		this.servicePlace.explore(this.offset)
		.subscribe(
		(data: any []) => {
			for (let element of data) {
				if (element.avatar == null || element.avatar == "") {
					element.avatar = '/assets/avatar.png';
				}

				if (element.chosendate != null && element.chosendate != "") {
					var datestring = element.chosendate.substring(0, 10);
					element.chosendate = datestring;
				}
				
				this.elements.push(element);
		    }
			
			this.loading = false;
			console.log("*****************");
			console.log(this.elements);
			console.log("*****************");
		});
	}
	
	
	loadMoreResult() {
		this.offset += 10;
		this.explore();
	}

}
