import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { UserService } from '../_services';
import { AuthenticationService } from '../_services';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.css']
})
export class MissionComponent implements OnInit {
	public error;
	public currentUser: User;
	public type;
	public userMissions;
	public allMissions;
	
	public dailyMarkerLoading: boolean;
	public dailyMarker: boolean;
	public missionLoading: boolean;
	public allMissionLoading: boolean;
	
	public errorMessage;
	
	public userMissionIdToPay;
	public tokensToPay;

  	constructor(private userService: UserService,
			private router: Router,
			private serviceAuthentication: AuthenticationService) { }
  	
 
  	roundNumber(current_count: number, count_max: number): number | null {
  		var num = current_count/count_max*10;
  		num = Math.round(num);
  		return num*10;
  	}  	
  	
  	viewAllMission() {
		$("#missions-section").show();
		$("#active-missions").hide();
	}
  	
  	closeAllMission() {
		$("#missions-section").hide();
		$("#active-missions").show();
	}

 	
	ngOnInit() {
		this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
		this.dailyMarkerLoading = true;
	    this.dailyMarker = true;
	    this.missionLoading = true;
	    this.allMissionLoading = true;
	    this.error = null;
	    
	    this.userService.checkMissions(null)
		.subscribe(res => {
			this.dailyMarker = false;
			for (let entry of res) {
				if (entry == "DAILY_MARKER") {
					this.dailyMarker = true;
				}
			}
        	this.dailyMarkerLoading = false;
		});
	    
	    this.userService.getUserMissions()
		.subscribe(res => {
			this.userMissions = res;
			console.log(this.userMissions);
        	this.missionLoading = false; 
		});
	    
	    this.userService.getAllMissions()
		.subscribe(res => {
			this.allMissions = res;
			console.log(this.allMissions);
        	this.allMissionLoading = false; 
		});
	}
	
	pay() {
		this.error = "";
		  
		$("#dialog-gift").hide();
		this.userService.pay(this.userMissionIdToPay).subscribe(
		    result => {
		    	console.log(result);
				if (result == -1) {
					this.error = "An error occured during the process [-1]";
				} else if (result == -2) {
					this.error = "No enough tokens to pay this mission";
				} else if (result == 0) {
					this.userMissions = null;
					$("#dialog-pay").hide();
					this.missionLoading = true;
					
					this.userService.getUserMissions()
					.subscribe(res => {
						this.userMissions = res;
						console.log(this.userMissions);
			        	this.missionLoading = false; 
					});
				} else {
					this.error = "An error occured during the process [UNKNOWN]";
				}
		     },
		    error => {
		    	console.error(error);
		    	this.error = "An error occured during the process";
		    } )
	}
	
	public openDialogPay (userMissionId, tokens) {
		this.error = null;
		$("#dialog-pay").show();
		this.userMissionIdToPay = userMissionId;
		this.tokensToPay = tokens;
	}
	
	public closePayDialog() {
  		$("#dialog-pay").hide();
	}
	
	createMarker() {
		this.dailyMarkerLoading = true;
		this.errorMessage = "";
		this.userService.claimMission("DAILY_MARKER")
		.subscribe(res => {
//			if (res) {
//				this.reloadHistoryAndWallet();
//			}
			this.dailyMarker = false;
        	this.dailyMarkerLoading = false;
		});
	}
	
	changeType(type: string) {
		$(".mission-type").removeClass('selected');
		$("#mission-" + type).addClass('selected');
		
		this.type = type;
		
		console.log("type: " + this.type);
	}

}
