import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { UserService } from '../_services';
import { AuthenticationService } from '../_services';

import { ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
	public currentUser: User;
	public referLoading: boolean;
	public amountLoading: boolean;
	public referredUserAmountLoading: boolean;
	public historyLoading: boolean;
	public badgeLoading: boolean;

	public dailyMarkerLoading: boolean;
	public dailyMarker: boolean;

	public amount: number;
	public referredUserAmount: number;
	public code: string;
	public history;
	public badges;
	public type;
	public errorMessage;

	constructor(private userService: UserService,
			private router: Router,
			private serviceAuthentication: AuthenticationService) {
		
	}
	
	dailyLogin() {
		this.errorMessage = "";
		this.errorMessage = "Work in progress";
	}
	
	reloadHistoryAndWallet() {
		this.amountLoading = true;
		this.userService.getWallet()
		.subscribe(res => {
        	this.amount = res.amount;
        	this.amountLoading = false;
		});
		
		this.historyLoading = true; 
		this.userService.getWalletHistory()
		.subscribe(res => {
			this.history = res;
			console.log(this.history);
        	this.historyLoading = false; 
		});
	}
	
	changeType(type: string) {
		$(".mission-type").removeClass('selected');
		$("#mission-" + type).addClass('selected');
		
		this.type = type;
		
	}
	
	public openBadge(url) {
		$("#dialog-gallery").show();
		$("#img-gallery").attr("src", url);
	}
	
	public closeBadge() {
		$("#dialog-gallery").hide();
	}
	
	referAfriend() {
		this.referLoading = true;
		$("#message").html("");
		if (this.code == null || this.code == "") {
			$("#message").html("Insert a code");
			this.referLoading = false;
		}
		
		console.log('code? ' + this.code);
		
		this.userService.referAfriend(this.code)
		.subscribe(res => {
        	if (res) {
        		this.historyLoading = true;
        		this.history = null;
        		
        		this.currentUser.referral_used = 1;
        		var data = JSON.stringify(this.currentUser);
    			localStorage.setItem('currentUser', data);
    			
    			this.amountLoading = true;
    			this.userService.getWallet()
    			.subscribe(res => {
    	        	this.amount = res.amount;
    	        	this.amountLoading = false;
    			});
    			
    			this.userService.getWalletHistory()
    			.subscribe(res => {
    				this.history = res;
    				console.log(this.history);
    	        	this.historyLoading = false; 
    			});
        	} else {
        		$("#message").html("Code not valid");
        	}
        	this.referLoading = false;
		});
		
		this.referLoading = false;
	}


	ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.amountLoading = true;
        this.historyLoading = true;
        this.badgeLoading = true;
        this.referredUserAmountLoading = true;
        
//        this.dailyMarkerLoading = true;
//        this.dailyMarker = true;
//        
//        this.userService.checkMissions(null)
//		.subscribe(res => {
//			this.dailyMarker = false;
//			for (let entry of res) {
//				if (entry == "DAILY_MARKER") {
//					this.dailyMarker = true;
//				}
//			}
//        	this.dailyMarkerLoading = false;
//		});
        
        this.userService.getWallet()
		.subscribe(res => {
        	this.amount = res.amount;
        	this.amountLoading = false;
		});
        
        this.userService.getUserReferred()
		.subscribe(res => {
        	this.referredUserAmount = res;
        	this.referredUserAmountLoading = false;
		});
        
        
        
        
        this.userService.getWalletHistory()
		.subscribe(res => {
			this.history = res;
			console.log(this.history);
        	this.historyLoading = false; 
		});
        
        this.userService.getBadges()
		.subscribe(res => {
			this.badges = res;
        	this.badgeLoading = false; 
		});
        
        
	}
	

}
