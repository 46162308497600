import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { UserService } from '../_services';
import { AuthenticationService } from '../_services';

import { ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

@Component({
  selector: 'app-updatenotification',
  templateUrl: './updatenotification.component.html',
  styleUrls: ['./updatenotification.component.css']
})
export class UpdatenotificationComponent implements OnInit {
	public currentUser: User;
	public loading: boolean = false;

	public file: File;
	public notificationBuddy: boolean;
	public notificationMarker: boolean;
	public notificationLike: boolean;
	public notificationGift: boolean;

	constructor(private userService: UserService,
			private router: Router,
			private serviceAuthentication: AuthenticationService) {
		
	}


	ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.loading = true; 
        $("#message").html("");
        
        this.userService.getNotificationSettings()
		.subscribe(res => {
			this.notificationBuddy = res.notification_friend;
			this.notificationMarker = res.notification_friend_marker;
			this.notificationLike = res.notification_like;
			this.notificationGift = res.notification_gift;
			
        	this.loading = false; 
		},
	    error => {
	    	this.loading = false; 
	        console.log('error on loading notification settings: ', error);
	        $("#message").html("error on loading notification settings");
	    });
        
		
	}
	
	updateNotification(type, value) {
		$("#message").html("");
		this.userService.updateNotification(type, value).subscribe((res: any []) => {
			if (type == 'buddy') {
				this.notificationBuddy = value;
			} else if (type == 'marker') {
				this.notificationMarker = value;
			} else if (type == 'like') {
				this.notificationLike = value;
			} else if (type == 'gift') {
				this.notificationGift = value;
			}
	    },
	    error => {
	        console.log('error on changing the notification setting: ', error);
	        $("#message").html("error on changing the notification setting");
	    });
		
		
		
	}
}
