import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { User } from '../_models';
import { Place } from '../_models';
import { UserService } from '../_services';
import { PlacesService, AuthenticationService } from '../_services';

import { ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';


@Component({
  selector: 'app-feed',
  templateUrl: './feed.component.html',
  styleUrls: ['./feed.component.css']
})
export class FeedComponent implements OnInit {
	public markers = [];
	public offset: number;
	public currentUser: User;
	public loading: boolean;
	

	constructor(private userService: UserService,
			private servicePlace: PlacesService,
			private serviceAuthentication: AuthenticationService,
		    private route: ActivatedRoute) {
		this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
	}

  	ngOnInit() {
  		if($(".page-footer").is(":visible")) {
	  		document.getElementById("search").classList.remove("search-active");
	  		document.getElementById("search").classList.add("search");
		    
		    document.getElementById("explore").classList.remove("fas");
		    document.getElementById("explore").classList.add("far");
		    
	  		document.getElementById("feed").classList.remove("feed");
	  		document.getElementById("feed").classList.add("feed-active");
	  		
		  	document.getElementById("statistics").classList.remove("fas");
			document.getElementById("statistics").classList.add("fal");
  		}
  		
  		this.offset = 0;
  		this.markers = [];
  		this.loadFeeds();
  	}

	private loadFeeds() {
		this.loading = true;
		this.servicePlace.feed(this.offset)
		.subscribe(
		(data: any []) => {
			for (let marker of data) {
				if (marker.user_avatar == null || marker.user_avatar == "") {
					marker.user_avatar = '/assets/avatar.png';
				}
				
				if (marker.chosendate != null && marker.chosendate != "") {
//					var d = new Date(marker.chosendate);
					var datestring = marker.chosendate.substring(0, 10);
					marker.chosendate = datestring;
				}
				this.markers.push(marker);
		    }
			
			this.loading = false;
			console.log("*****************");
			console.log(this.markers);
			console.log("*****************");
		});
	}
	
	loadMoreResult() {
		this.offset += 10;
		this.loadFeeds();
	}
}
