import { User } from ".";

export class Comment {
    public description:string;
	public user: User;
	public username:string;

	
	constructor(id, firstName, lastName, avatar, description) {
	 	this.description = description;
	 	this.user = new User(id, "", firstName, "", lastName);
	 	this.user.avatar = avatar;
	}
	
}