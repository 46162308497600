import { Component, OnInit } from '@angular/core';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';


//Google Maps Import Angular 6+
/// <reference types="googlemaps" />
import { MapsAPILoader } from '@agm/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { countries, languages, continents} from 'countries-list';
import { PlacesService, AuthenticationService, UserService } from './_services';
import { Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';

declare var google: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
    public showHead: boolean = true;
    public showFooter: boolean = true;
    public showCustom: boolean = false;
    public customTitle: string = "";
    
    //UserData
    private grant_type: string = 'password';
    private client_id: number = 3;
    private client_secret: string = '2vf2WuhlF8ecgGszUdVT7yQuo5lDfe8IQtXiuwKr';
    private username: string = 'babic.amir.ab@gmail.com';
    private password: string = 'Amir5432';
    private scope: string = '';

//    public loginData = {
//        "grant_type": this.grant_type,
//        "client_id": this.client_id,
//        "client_secret": this.client_secret,
//        "username": this.username,
//        "password": this.password,
//        "scope": this.scope
//    }

	public notifications: number = null;
	public feeds: number = null;

  logoPath:any = "../assets/Logo.png";
  public latitude: number;
  public longitude: number;
  public searchControl: FormControl;
  public zoom: number;

  //Variable for Places
  public places = [] /*Marker*/;
  //Variable for new place
  public newPlace: any;

	backClicked() {
		this.location.back();
  	}
  	
    getDeviceType() {
  	  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(navigator.userAgent)) {
  	    return "tablet";
  	  }
  	  if (
  	    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
  	    		navigator.userAgent
  	    )
  	  ) {
  	    if (navigator.userAgent.includes("Android") || navigator.userAgent.includes("android")) {
  	    	return "Android";
  	    } else if (navigator.userAgent.includes("iPhone")) {
  	  	    return "iOS";
  	  	} else if (navigator.userAgent.includes("Chrome")) {
  	  		return "Chrome Web Push";
  	    } else if (navigator.userAgent.includes("Firefox")) {
  	  		return "Firefox";
  	    } else {
  	  		return "mobile";
  	    }
  	  }
  	  
	    if (navigator.userAgent.includes("Chrome")) {
	  		return "Chrome Web Push";
	    } else if (navigator.userAgent.includes("Firefox")) {
	  		return "Firefox";
	    } 
  	  	return "desktop";
  }
  
  constructor(
  	private location: Location,
    private mapsAPILoader: MapsAPILoader, 
    private ngZone: NgZone,
    private servicePlace: PlacesService,
    private serviceAuthentication: AuthenticationService,
    private userService: UserService,
    private router: Router) {
	  
	  //oneSignal.init({ appId: '75e73fa4-be82-4548-9764-af68eacf9397' });
	  

//	  	this.oneSignal.init({
//	      appId: "75e73fa4-be82-4548-9764-af68eacf9397",
//	      safari_web_id: "web.onesignal.auto.639a6cf4-24b5-4e34-8f2b-dfd5fe45e04f",
//	      allowLocalhostAsSecureOrigin: true,
//	      notifyButton: {
//	        enable: true,
//	      }
//	    });
//	  	
	  	// get device type TODO
	  	//HttpHeaders user-agent
//	  	console.log("TEST " + navigator.userAgent);
//	  	console.log("TEST " + this.getDeviceType());
	  
        router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
            	if (event['url'].includes('/login') == false
            			&& event['url'].includes('/register') == false
            			&& event['url'].includes('/reset') == false
            			&& event['url'].includes('/splash') == false
            			&& event['url'].includes('/underconstruction') == false) {
            		this.checkNotificationNumber();
            	}
            	
            	if (event['url'] == '/' ||
            			 event['url'].includes('/personprofile')
            			 || event['url'].includes('/explore')
            			 || event['url'].includes('/store')
            			 || event['url'].includes('/wallet')
            			 || event['url'].includes('/mission')
            			 || event['url'].includes('/feed')
            			 || event['url'].includes('/nft')
            			 || event['url'].includes('/home')) {
                    this.showHead = true;
                    this.showFooter = true;
                    this.showCustom = false;
                }

            	// NO HEADER / NO FOOTER PAGES
                if (event['url'].includes('/login') 
                    || event['url'].includes('/register')
                    || event['url'].includes('/reset')
                    || event['url'].includes('/splash')) {
                    this.showHead = false;
                    this.showFooter = false;
                    this.showCustom = false;
                }
                 
                // NO FOOTER
                if (event['url'].includes('/addcity')
                	|| event['url'].includes('/addcity/tags')
            		|| event['url'].includes('/addcity/rating')
        			|| event['url'].includes('/addcity/photos')
    				|| event['url'].includes('/addcity/thingsnottodo')
					|| event['url'].includes('/addcity/thingstodo')
					|| event['url'].includes('/citydetail')
					|| event['url'].includes('/profileicon')
					|| event['url'].includes('/buyvan')
					|| event['url'].includes('/wallet')
					|| event['url'].includes('/updatebio')
					|| event['url'].includes('/updatenotification')
//					|| event['url'].includes('/markers')
					) {
                	this.showFooter = false;
                } 
                
                // CUSTOM HEADER
                if (event['url'].includes('/addcity')
                	|| event['url'].includes('/addcity/tags')
            		|| event['url'].includes('/addcity/rating')
        			|| event['url'].includes('/addcity/photos')
    				|| event['url'].includes('/addcity/thingsnottodo')
					|| event['url'].includes('/addcity/thingstodo')
					|| event['url'].includes('/citydetail')
//					|| event['url'].includes('/markers')
					) {
                	this.showCustom = true;
                	this.showHead = false;
                } 
            }
        });

    }

  ngOnInit() {
  }
  
  private checkNotificationNumber() {
		this.userService.notificationNumber()
		.subscribe(res => {
			if(res == null) {
				this.notifications = 0;
				this.feeds = 0;
			}
			for (let entry of res.notifications) {
				this.notifications = entry.NUMBER;
			}
			
			for (let entry of res.feeds) {
				this.feeds = entry.NUMBER;
			}
			
			console.log("notifications found: " + this.notifications + " feeds found: " +this.feeds );
		});
	}
  
}

//Marker interface
interface Marker{
  name: string,
  location: { 
    lat: string,
    long: string
  },
  google_city_id: string
  name_short: string,
  country_long: string,
  country_short: string,
  elevation: number,
  //visited: boolean, 
  continent: string
}