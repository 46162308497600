import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Addcity } from '../_models';
import { Place } from '../_models';
import { Tip } from '../_models';
import { User } from '../_models';
import { UserService, BuddiesService } from '../_services';
import { ElementRef, NgZone, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { FormControl } from '@angular/forms';
//Google Maps Import Angular 6+
/// <reference types="googlemaps" />
import { MapsAPILoader } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window'
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { countries, languages, continents} from 'countries-list';
import { PlacesService, AuthenticationService } from '../_services';

declare var google: any;

@Component({
    //selector: 'app-root',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.css']
  })
export class HomeComponent implements OnInit {
	public addMarkerIconPath:any = "assets/icon.png";
//	public markerIconPath:any = "assets/icon_pin.png";
	public markerIconPath:any = {
        url: 'assets/icon_pin.png',
        scaledSize: {
          width: 25,
          height: 35
        }};
	public markerIconPathPlanned:any = {
        url: 'assets/icon_pin_planned.png',
        scaledSize: {
          width: 25,
          height: 35
        }};
	public currentUser: User;
	public users: User[] = [];
	public latitude: number;
	public longitude: number;
	public searchControl: FormControl;
	public zoom: number;
	//Variable for Places
	public places = [] /*Marker*/;
	//Variable for new place
	public newPlace: any;
	public city: Addcity;
	public month: string;
	public year: number;
	public counter: number;
	// user extra info 
	public motto: string;
	public currentPlace: Place;
	public nextPlaces: Place[];
	
	public buddyDetail:any;
	public loading: boolean;
	public markersLoading: boolean;
	public nobuddies: boolean;
	
	public markerIdToDelete: number;
	
	public markers = [];
	public offset: number;

    constructor(private userService: UserService,private buddyService: BuddiesService, private mapsAPILoader: MapsAPILoader, 
        private ngZone: NgZone, private authenticationService: AuthenticationService, private router: Router,
        private servicePlace: PlacesService) {
    	
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        
        if (this.currentUser.referral == null || this.currentUser.referral == '') {
        	this.authenticationService.logout();
        	this.router.navigate(['/']);
        }
        
        
    }
    
    ngOnInit() {
    	this.loading = false;
    	this.markersLoading = false;
    	this.offset = 0;
  		this.markers = [];
  		
    	document.getElementById("home").classList.remove("home");
	    document.getElementById("home").classList.add("home-active");
	
	    document.getElementById("search").classList.remove("search-active");
	    document.getElementById("search").classList.add("search");
	
	    document.getElementById("explore").classList.remove("fas");
	    document.getElementById("explore").classList.add("far");
	
	    document.getElementById("feed").classList.remove("feed-active");
	    document.getElementById("feed").classList.add("feed");
	
	    try {
	    	document.getElementById("statistics").classList.remove("fas");
		    document.getElementById("statistics").classList.add("fal");
		} catch (e) {
			
		}
	    
	    
//	    this.authenticationService.getUser().pipe(first())
//        .subscribe((data:any) => {
//    		this.currentUser = JSON.parse(data);
//    		this.currentUser.firstName = JSON.parse(data).name;
//    		data = JSON.stringify(this.currentUser);
//    		console.log("*** currentUser ***");
//    		console.log(this.currentUser);
//    		
//    		localStorage.setItem('currentUser', data);
//        },
//        error => {
//           // KO 
//        });
	    
	    // load buddies
	    this.loadAllBuddies();
	    // load markers
	    this.loadUserMarkes();
	    // load user info as badges etc
	    this.loadUserInfo();
	    
	    //Get all places from the Database
	    this.servicePlace.getPlaces()
    	//Save observable in places variable
	    .subscribe(
	        (data: any [] /*places[]*/) => {
		        /*console.log(data));*/
		        this.places = data;
			    console.log(this.places);
			    for (let place of this.places) {
					if (place.planned == 0) {
						place.marker = this.markerIconPathPlanned;
					} else {
						place.marker = this.markerIconPath;
					}
			    }
		        this.makeNumber();
	        });

	    //Set Google Maps defaults
	    this.zoom = 12;
	    this.latitude = 47.36667;
	    this.longitude = 8.55;
	
	    //set current position
	    this.setCurrentPosition();
	    
	    
	    
    }//onInit

    deleteUser(id: number) {
        this.userService.delete(id).pipe(first()).subscribe(() => { 
            this.loadAllBuddies() 
        });
    }
    
    clipboard() {
	    document.addEventListener('copy', (e: ClipboardEvent) => {
	      e.clipboardData.setData('text/plain', ('https://www.vanderon.app/personprofile/' + this.currentUser.id));
	      e.preventDefault();
	      document.removeEventListener('copy', null);
	    });
	    document.execCommand('copy');
	  }
    
    private truncate(input) {
	   if (input != null && input.length > 32) {
	      return input.substring(0, 32) + '...';
	   }
	   return input;
	};
    
    public getCityDetail(place) {
    	this.city = new Addcity(place.name);
    	this.city.id = place.id;
    	this.city.cityNameShort = place.name_short;
    	this.city.description = this.truncate(place.description);
    	this.city.rating = place.rating;
    	this.city.planned= place.planned;
    	this.city.todo = [];
    	this.city.nottodo = [];
		
    	for (let todo of place.todos) {
    		if (todo.todo == 1) {
    			this.city.todo.push(new Tip(todo.text ,todo.like));
    		} else {
    			this.city.nottodo.push(new Tip(todo.text ,todo.like));
    		}
    	}
    	
    	// controls
        if (place.chosendate != null) {
  	      var month = new Array();
  	      month[0] = "January";
  	      month[1] = "February";
  	      month[2] = "March";
  	      month[3] = "April";
  	      month[4] = "May";
  	      month[5] = "June";
  	      month[6] = "July";
  	      month[7] = "August";
  	      month[8] = "September";
  	      month[9] = "October";
  	      month[10] = "November";
  	      month[11] = "December";
  	      place.chosendate = place.chosendate.replace(/\-/g, '/'); // FIX IOS
  	      this.city.month = month[new Date(place.chosendate).getMonth()];
  	      this.city.year = new Date(place.chosendate).getFullYear();
  	      this.city.chosenDate = place.chosendate;
        }
    	
    	/*
    	this.city = new Addcity("Genoa");
    	this.city.user = new User(1, "username", "Luca", null ,"Migliore"); 
    	this.city.user.avatar = "/assets/avatar.png";
    	this.city.isvisited = true;
    	this.city.dateVisited = new Date();
    	this.city.howlong = 3;
    	this.city.rating = 4;
    	this.city.chosenTagList = [new Place(1, "Culture"), new Place(1, "Relax"), new Place(1, "Shopping"), new Place(1, "Nightlife")];
    	this.city.photos = [];
		this.city.photos.push("https://www.handiscover.com/content/wp-content/uploads/2016/10/Accessible-Genoa-For-Disabled.jpg");
		this.city.photos.push("http://www.orangesmile.com/common/img_cities_original/genoa--118400-2.jpg");
		this.city.photos.push("https://www.handiscover.com/content/wp-content/uploads/2016/10/Accessible-Genoa-For-Disabled.jpg");
		this.city.photos.push("https://destinationsmagazine.com/wp-content/uploads/2018/04/Genoa-Boccadasse-iStock-504342719-maudanros.jpg");
		this.city.photos.push("https://destinationsmagazine.com/wp-content/uploads/2018/04/Genoa-Boccadasse-iStock-504342719-maudanros.jpg");
		this.city.todo = [];
		this.city.todo.push(new Tip("Visit the old port",55));
		this.city.todo.push(new Tip("Visit the San Lorenzo church",40));
		this.city.todo.push(new Tip("Eat the famous \"focaccia di recco\" in some local bakery in the center",30));
		this.city.nottodo = [];
		this.city.nottodo.push(new Tip("Don't come with the car. There's no many parking spaces",100));
		this.city.nottodo.push(new Tip("Don't walk on the old port during the evening. It's not safe",80));
		
		
		
		if (this.city.photos.length > 4) {
			this.counter = this.city.photos.length - 3;
		} else {
			this.counter = 0;
		}
		*/
    }
    
	private loadUserMarkes() {
		this.markersLoading = true;
		this.servicePlace.searchPlacesByUserId(this.currentUser.id, this.offset)
		.subscribe(
		(data: any []) => {
			for (let marker of data) {
				if (marker.user_avatar == null || marker.user_avatar == "") {
					marker.user_avatar = '/assets/avatar.png';
				}
				
				if (marker.chosendate != null && marker.chosendate != "") {
					var datestring = marker.chosendate.substring(0, 10);
					marker.chosendate = datestring;
				}
				this.markers.push(marker);
		    }
			
			this.markersLoading = false;
		});
	}
	
	loadMoreResult() {
		this.offset += 10;
		this.loadUserMarkes();
	}
	
	private loadUserInfo() {
		this.buddyService.getBuddyByUserId(this.currentUser.id).subscribe(res=> {
  			if (res.badges_count == null) {
  				res.badges_count = 0;
  			}
  			
  			if (res.places_count == null) {
  				res.places_count = 0;
  			}
  			
  			this.currentUser.badges_count = res.badges_count;
  			this.currentUser.places_count = res.places_count;
  			this.currentUser.badges = res.badges;
  		});
	}
	
    private loadAllBuddies() {
    	this.nobuddies = true;
    	this.loading = true;
    	console.log("**** enter in loadAllBuddies");
    	this.users = [];
		this.buddyService.getBuddies().subscribe(res=>{
			if(res == null)
				return;

			for (let buddy of res) {
				this.nobuddies = false;
				
				var user = new User(buddy.id,buddy.name,"name","pw","surname");
				if (buddy.avatar != null && buddy.avatar != "") {
					user.avatar = buddy.avatar;
				}
				user.markers = buddy.last_markers;
				
				this.users.push(user);
			}
			
			
		})
		.add(() => {
			this.loading = false;
		});
    }
    
    //Convert String to number from Places (lat/long/elevation)
    private makeNumber(){
        for (let index = 0; index < this.places.length; index++) {
          //Make the Location Data from Strings to Numbers
          this.places[index].location.lat = +this.places[index].location.lat;
          this.places[index].location.long = +this.places[index].location.long;
          this.places[index].elevation = +this.places[index].elevation;
        }
    }
  
    //If Geolocation active --> set actual coordinates as center of map
    public setCurrentPosition() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
        	// TODO doesn't work very well
//        	console.log("Geolocation is active latitude ["+position.coords.latitude+"] longitude ["+position.coords.longitude+"]");
//          this.latitude = position.coords.latitude;
//          this.longitude = position.coords.longitude;
//          this.zoom = 12;
        });
      }
    }//setCurrentPosition    
  }
  
  //Marker interface
  interface Marker{
    name: string,
    location: { 
      lat: string,
      long: string
    },
    google_city_id: string
    name_short: string,
    country_long: string,
    country_short: string,
    elevation: number,
    visited: boolean, 
    continent: string
  }