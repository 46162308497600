import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { AlertService, UserService } from '../_services';

@Component({
    templateUrl: 'register.component.html',
    styleUrls: ['register.component.css']
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;

    constructor(
		private location: Location,
        private formBuilder: FormBuilder,
        private router: Router,
        private userService: UserService,
        private alertService: AlertService) { }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', Validators.required],
            terms: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            password_confirmation: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    backClicked() {
		this.location.back();
  	}
    
    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        this.loading = true;
        this.userService.register(this.registerForm.value.name,
        		this.registerForm.value.email, this.registerForm.value.password,
        		this.registerForm.value.password_confirmation)
            .pipe(first())
            .subscribe(
                data => {
                	if (data.email != null && data.email.includes('The email must be a valid email address.')) {
        				this.alertService.error('The email must be a valid email address.');
        			} else if (data.email != null && data.email.includes('The email has already been taken.')) {
        				this.alertService.error('The email has already been taken.');
        			} else if (data.name.includes('The name has already been taken.')) {
        				this.alertService.error('The name has already been taken.');
        			} else if (data.password != null && data.password.includes('The password confirmation does not match.')) {
        				this.alertService.error('The password confirmation does not match.');
        			} else {
        				this.alertService.success('Registration successful', true);
                        this.router.navigate(['/login']);
        			}
                	
                	this.loading = false;
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}
